import React from "react";
import styled, { css } from "styled-components";
import { useCleeckyCoreTheme } from "../../themes/themeUtilities/CleeckyCoreThemeProvider";

interface CleeckyCoreTextHighlightProps {
  children: React.ReactNode;
  color?: string;
  mode?: {
    highlight?: boolean;
    underline?: "below" | "behind";
    thick?: boolean;
    marker?: boolean;
    outline?: boolean;
    underlineConfig?: {
      thickness?: number;
      distance?: number;
    };
  };
}

interface StyledTextProps {
  $color: string;
  $mode: {
    highlight: boolean;
    underline: "below" | "behind" | undefined;
    thick: boolean;
    marker: boolean;
    outline: boolean;
    underlineConfig: {
      thickness: number;
      distance: number;
    };
  };
}

const StyledText = styled.span<StyledTextProps>`
  position: relative;
  display: inline-block;

  ${({ $color, $mode }) => css`
    /* Modo highlight - muda a cor do texto */
    ${$mode.highlight &&
    css`
      color: ${$color};
    `}

    /* Modo underline */
    ${$mode.underline &&
    css`
      ${$mode.underline === "below"
        ? css`
            border-bottom: ${$mode.underlineConfig.thickness}px solid ${$color};
            padding-bottom: ${$mode.underlineConfig.distance}px;
          `
        : css`
            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              width: 100%;
              height: ${$mode.thick ? "0.6em" : "0.4em"};
              background-color: ${$color};
              opacity: 0.3;
              z-index: -1;
              transform: translateY(-50%);
            }
          `}
    `}

    /* Modo marker - fundo marca-texto */
    ${$mode.marker &&
    css`
      background: ${$color}40;
      padding: 0 4px;
      border-radius: 3px;
    `}

    /* Modo outline - borda em volta dos caracteres */
    ${$mode.outline &&
    css`
      -webkit-text-stroke: ${$mode.thick ? "1.5px" : "1px"} ${$color};
      text-stroke: ${$mode.thick ? "1.5px" : "1px"} ${$color};
    `}
  `}
`;

export const CleeckyCoreTextHighlight: React.FC<
  CleeckyCoreTextHighlightProps
> = ({ children, color, mode = {} }) => {
  const { currentTheme, currentGradient } = useCleeckyCoreTheme();

  const defaultMode = {
    highlight: mode.highlight ?? false,
    underline: mode.underline,
    thick: mode.thick ?? false,
    marker: mode.marker ?? false,
    outline: mode.outline ?? false,
    underlineConfig: {
      thickness: mode.underlineConfig?.thickness ?? 2,
      distance: mode.underlineConfig?.distance ?? 2,
    },
  };

  const highlightColor =
    color ||
    currentGradient?.highlightColor ||
    currentTheme.colors.interactive.clickable;

  return (
    <StyledText $color={highlightColor} $mode={defaultMode}>
      {children}
    </StyledText>
  );
};
