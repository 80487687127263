import { useState, useEffect } from "react";
import { CleeckyCoreTheme } from "../themes/CleeckyCoreTheme";
import { CleeckyCoreGradientTheme } from "../themes/CleeckyCoreGradientTheme";
import { interfaceThemes } from "../../themes";

interface ThemeConfig {
  interfaceTheme: CleeckyCoreTheme;
  gradientTheme?: CleeckyCoreGradientTheme;
}

export const useCleeckyCoreTheme = (
  initialInterface?: CleeckyCoreTheme,
  initialGradient?: CleeckyCoreGradientTheme
) => {
  const defaultTheme = interfaceThemes.lightTheme;

  const [themeConfig, setThemeConfig] = useState<ThemeConfig>({
    interfaceTheme: initialInterface || defaultTheme,
    gradientTheme: initialGradient,
  });

  const setInterfaceTheme = (newTheme: CleeckyCoreTheme) => {
    setThemeConfig((prev) => ({
      ...prev,
      interfaceTheme: newTheme,
    }));
  };

  const setGradientTheme = (newGradient?: CleeckyCoreGradientTheme) => {
    setThemeConfig((prev) => ({
      ...prev,
      gradientTheme: newGradient,
    }));
  };

  return {
    currentTheme: {
      ...themeConfig.interfaceTheme,
      gradient: themeConfig.gradientTheme,
    },
    setInterfaceTheme,
    setGradientTheme,
  };
};
