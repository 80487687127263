import React from "react";
import styled from "styled-components";
import { ColumnProps } from "../../../types";

const StyledColumn = styled.div<{
  $breakpoints: Record<string, number | undefined>;
  $align?: string;
  $justify?: string;
  $gap?: string | number;
  $padding?: string | number;
  $margin?: string | number;
  $width?: string;
  $height?: string;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  min-height: min-content;
  max-height: 100vh;

  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $justify }) => $justify && `justify-content: ${$justify};`}
  ${({ $gap }) =>
    $gap && `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`}
  ${({ $padding }) =>
    $padding &&
    `padding: ${typeof $padding === "number" ? `${$padding}px` : $padding};`}
  ${({ $margin }) =>
    $margin &&
    `margin: ${typeof $margin === "number" ? `${$margin}px` : $margin};`}
  ${({ $width }) => $width && `width: ${$width};`}

  ${({ $breakpoints, theme }) => {
    const breakpointStyles = Object.entries($breakpoints).map(
      ([breakpoint, size]) => {
        if (!size) return "";
        const width = (size / 12) * 100;
        return `
        @media (min-width: ${theme.responsive.breakpoints[breakpoint]}) {
          flex: 0 0 ${width}%;
          max-width: ${width}%;
        }
      `;
      }
    );
    return breakpointStyles.join("\n");
  }}
`;

export const CleeckyCoreColumn: React.FC<ColumnProps> = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  "2xl": xxl,
  align,
  justify,
  gap,
  padding,
  margin,
  width,
  height,
  className,
}) => {
  const breakpoints = { xs, sm, md, lg, xl, "2xl": xxl };

  return (
    <StyledColumn
      $breakpoints={breakpoints}
      $align={align}
      $justify={justify}
      $gap={gap}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      className={className}>
      {children}
    </StyledColumn>
  );
};
