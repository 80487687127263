import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { counterReducer } from "./slices"; // Importe o reducer usando o index

const store = configureStore({
  reducer: {
    counter: counterReducer, // Adicione o reducer ao store
    // Adicione outros reducers aqui
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
