import styled, { css, keyframes } from "styled-components";
import { CleeckyCoreGradientAnimation } from "../animations/CleeckyCoreGradientAnimations";

interface GradientBackgroundProps {
  $enableAnimation?: boolean;
  $animation?: CleeckyCoreGradientAnimation;
}

const createKeyframes = (animation: CleeckyCoreGradientAnimation) => keyframes`
  ${animation.keyframes}
`;

export const CleeckyCoreGradientBackground = styled.div<GradientBackgroundProps>`
  position: relative;
  background: ${({ theme, style }) =>
    style?.background ||
    (theme.gradient
      ? `${theme.gradient.backgroundImage}`
      : theme.colors.background.base)};
  background-image: ${({ style }) => style?.backgroundImage};
  background-attachment: fixed;
  background-size: ${({ theme }) =>
    theme.gradient?.backgroundSize || "400% 400%"};
  background-position: center;

  ${({ $enableAnimation, $animation, theme }) =>
    $enableAnimation &&
    theme.gradient?.enableAnimation &&
    $animation &&
    css`
      animation: ${createKeyframes($animation)} ${$animation.duration}
        ${$animation.timingFunction} ${$animation.iterationCount};
    `}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    background-attachment: fixed;
    background-size: inherit;
    background-position: inherit;
    z-index: -1;
  }
`;
