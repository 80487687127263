import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { CleeckyCoreBaseInput, BaseInputProps } from "./CleeckyCoreBaseInput";
import { t } from "i18next";

interface ConfirmPasswordInputProps extends Omit<BaseInputProps, "type"> {
  showToggleButton?: boolean;
  compareWith?: string;
  value?: string;
}

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

export const CleeckyCoreConfirmPasswordInput = React.forwardRef<
  HTMLInputElement,
  ConfirmPasswordInputProps
>((props, ref) => {
  const {
    showToggleButton = true,
    compareWith,
    onChange,
    error,
    value,
    label = t("cleeckycore:common.form.confirmPassword.label"),
    placeholder = t("cleeckycore:common.form.confirmPassword.placeholder"),
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const validatePasswordMatch = (
    currentValue: string | undefined
  ): string | undefined => {
    if (compareWith && currentValue && currentValue !== compareWith) {
      return t("cleeckycore:common.form.confirmPassword.invalid");
    }
    return undefined;
  };

  return (
    <CleeckyCoreBaseInput
      ref={ref}
      type={showPassword ? "text" : "password"}
      prefix={<FontAwesomeIcon icon={faLock} />}
      suffix={
        showToggleButton && (
          <ToggleButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={
              showPassword
                ? t("cleeckycore:common.form.password.hide")
                : t("cleeckycore:common.form.password.show")
            }>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </ToggleButton>
        )
      }
      error={error || validatePasswordMatch(value)}
      onChange={onChange}
      value={value}
      label={label}
      placeholder={placeholder}
      {...rest}
    />
  );
});

CleeckyCoreConfirmPasswordInput.displayName = "CleeckyCoreConfirmPasswordInput";
