import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes, routeComponents } from "./routes";
import { Home } from "../pages";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Rota Home */}
        <Route path={routes.home} element={<Home />} />

        {/* Todas as outras rotas */}
        {Object.entries(routeComponents)
          .filter(([path]) => path !== routes.home)
          .map(([path, Component]) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
