import React from "react";
import styled, { css } from "styled-components";
import { CleeckyCoreGradientBackground } from "../../themes/themeUtilities/CleeckyCoreBackground";
import { CleeckyCoreGradientAnimation } from "../../themes/animations/CleeckyCoreGradientAnimations";
import { useCleeckyCoreTheme } from "../../themes/themeUtilities/CleeckyCoreThemeProvider";

type BackgroundSize = "fullscreen" | "fullspace" | "element" | "custom";

interface BackgroundProps {
  children?: React.ReactNode;
  color?: string;
  gradient?: boolean;
  size?: BackgroundSize;
  customSize?: {
    width?: string;
    height?: string;
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
  };
  enableAnimation?: boolean;
  animation?: CleeckyCoreGradientAnimation;
  className?: string;
}

interface StyledBackgroundProps {
  $color?: string;
  $size: BackgroundSize;
  $customSize?: BackgroundProps["customSize"];
}

const BaseBackground = styled.div<StyledBackgroundProps>`
  position: relative;
  background: ${({ $color, theme }) => $color || theme.colors.background.base};

  ${({ $size, $customSize }) => {
    switch ($size) {
      case "fullscreen":
        return css`
          min-height: 100vh;
          width: 100vw;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `;
      case "fullspace":
        return css`
          min-height: 100%;
          width: 100%;
          flex: 1;
        `;
      case "element":
        return css`
          height: 100%;
          width: 100%;
        `;
      case "custom":
        return css`
          width: ${$customSize?.width || "auto"};
          height: ${$customSize?.height || "auto"};
          min-width: ${$customSize?.minWidth || "auto"};
          min-height: ${$customSize?.minHeight || "auto"};
          max-width: ${$customSize?.maxWidth || "none"};
          max-height: ${$customSize?.maxHeight || "none"};
        `;
      default:
        return "";
    }
  }}
`;

export const CleeckyCoreBackground: React.FC<BackgroundProps> = ({
  children,
  color,
  gradient = false,
  size = "element",
  customSize,
  enableAnimation,
  animation,
  className,
}) => {
  const { currentGradient } = useCleeckyCoreTheme();

  if (gradient) {
    const backgroundStyle = !enableAnimation
      ? {
          background: currentGradient?.highlightColor || color,
          backgroundImage: "none",
        }
      : {};

    return (
      <CleeckyCoreGradientBackground
        $enableAnimation={enableAnimation}
        $animation={animation}
        className={className}
        style={{
          ...(size === "fullscreen" && {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: "100vh",
            width: "100vw",
          }),
          ...(size === "fullspace" && {
            minHeight: "100%",
            width: "100%",
            flex: 1,
          }),
          ...(size === "element" && {
            height: "100%",
            width: "100%",
          }),
          ...(size === "custom" && {
            width: customSize?.width || "auto",
            height: customSize?.height || "auto",
            minWidth: customSize?.minWidth || "auto",
            minHeight: customSize?.minHeight || "auto",
            maxWidth: customSize?.maxWidth || "none",
            maxHeight: customSize?.maxHeight || "none",
          }),
          ...backgroundStyle,
        }}>
        {children}
      </CleeckyCoreGradientBackground>
    );
  }

  return (
    <BaseBackground
      $color={color}
      $size={size}
      $customSize={customSize}
      className={className}>
      {children}
    </BaseBackground>
  );
};
