import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { appRoutes } from "../../routes/routes";
import * as CleeckyCore from "../../CleeckyCore";
import { useCleeckyCoreTheme } from "../../CleeckyCore/themes/themeUtilities/CleeckyCoreThemeProvider";
import { AuthModal } from "components/Auth/AuthModal";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppRoute } from "../../routes/routes";
import { AppToolsModal } from "components";

export const Home: React.FC = () => {
  const navigate = useNavigate();
  const { currentGradient } = useCleeckyCoreTheme();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState<AppRoute | null>(null);

  const handleAccountClick = () => {
    setIsAuthModalOpen(true);
  };

  const handleAppClick = (app: AppRoute) => {
    if (app.tools?.length) {
      setSelectedApp(app);
    } else {
      navigate(app.path);
    }
  };

  return (
    <CleeckyCore.Background
      gradient
      size="fullscreen"
      enableAnimation={currentGradient?.enableAnimation}
      animation={currentGradient?.animation}>
      <CleeckyCore.Container>
        <CleeckyCore.Column
          align="center"
          justify="center"
          padding="2rem 0"
          gap="2rem">
          <CleeckyCore.Row justify="center" align="center">
            <h1 style={{ fontSize: "3rem", color: "white" }}>Cleecky.com</h1>
          </CleeckyCore.Row>

          <CleeckyCore.Row justify="center">
            <CleeckyCore.SearchBar
              showSearchIcon
              showFilterIcon={false}
              showAccountIcon
              textAlign="left"
              onSearch={(term) => console.log("Search term:", term)}
              onAccountClick={handleAccountClick}
              accountTooltip={t("project:common.actions.access_your_cleecky")}
              placeholder={t("project:common.actions.searching_for")}
              size="large"
              variant="solid"
              borderRadius
            />
          </CleeckyCore.Row>

          <CleeckyCore.Section>
            <CleeckyCore.Row>
              {Object.entries(appRoutes).map(([sectionName, routes]) => (
                <CleeckyCore.Grid
                  key={sectionName}
                  gap="3rem"
                  columns={{ sm: 4, md: 5 }}
                  justify="center">
                  {routes.map((route) => (
                    <CleeckyCore.Button
                      key={route.path}
                      format="square"
                      icon={<FontAwesomeIcon icon={route.icon} />}
                      text={route.name}
                      onClick={() => handleAppClick(route)}
                      variant="solid"
                      size="large"
                      borderRadius="1rem"
                      textPosition="outside-below"
                      animation={{
                        type: "scale",
                        trigger: "hover",
                      }}
                    />
                  ))}
                </CleeckyCore.Grid>
              ))}
            </CleeckyCore.Row>
          </CleeckyCore.Section>
        </CleeckyCore.Column>
      </CleeckyCore.Container>
      <CleeckyCore.ThemeSwitcher />
      <CleeckyCore.LanguageSwitcher />
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
      {selectedApp && (
        <AppToolsModal
          isOpen={!!selectedApp}
          onClose={() => setSelectedApp(null)}
          app={selectedApp}
        />
      )}
    </CleeckyCore.Background>
  );
};
