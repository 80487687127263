import { createGlobalStyle } from "styled-components";
import { CleeckyCoreTheme } from "./CleeckyCoreTheme";

export const CleeckyCoreGlobalStyle = createGlobalStyle<{
  theme: CleeckyCoreTheme;
}>`
  :root {
    font-size: ${({ theme }) => theme.responsive.fontSize.base};
    
    @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
      font-size: ${({ theme }) => theme.responsive.fontSize.mobile};
    }
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
  }

  /* Seleção de texto */
  ::selection {
    background-color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.selection.background};
    color: ${({ theme }) => theme.colors.text.onGradient};
  }
`;
