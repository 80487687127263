export const formatPhoneNumber = (
  value: string,
  format: string = "BR"
): string => {
  const digits = value.replace(/\D/g, "");

  switch (format) {
    case "BR":
      if (digits.length <= 11) {
        return digits.replace(
          /^(\d{0,2})(\d{0,5})(\d{0,4})/,
          (_, ddd, first, second) => {
            if (second) return `(${ddd}) ${first}-${second}`;
            if (first) return `(${ddd}) ${first}`;
            if (ddd) return `(${ddd}`;
            return "";
          }
        );
      }
      break;
    case "US":
      if (digits.length <= 10) {
        return digits.replace(
          /^(\d{0,3})(\d{0,3})(\d{0,4})/,
          (_, area, first, second) => {
            if (second) return `(${area}) ${first}-${second}`;
            if (first) return `(${area}) ${first}`;
            if (area) return `(${area}`;
            return "";
          }
        );
      }
      break;
  }
  return digits;
};
