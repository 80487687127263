export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export interface BreakpointConfig {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
}

export const defaultBreakpoints: BreakpointConfig = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

export interface ResponsiveConfig {
  breakpoints: BreakpointConfig;
  containerMaxWidths: {
    [key in Breakpoint]: string;
  };
  spacing: {
    safeAreaTop: string;
    safeAreaBottom: string;
    safeAreaLeft: string;
    safeAreaRight: string;
  };
  fontSize: {
    base: string;
    mobile: string;
  };
}

export const defaultResponsiveConfig: ResponsiveConfig = {
  breakpoints: defaultBreakpoints,
  containerMaxWidths: {
    xs: "100%",
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1140px",
    "2xl": "1320px",
  },
  spacing: {
    safeAreaTop: "env(safe-area-inset-top)",
    safeAreaBottom: "env(safe-area-inset-bottom)",
    safeAreaLeft: "env(safe-area-inset-left)",
    safeAreaRight: "env(safe-area-inset-right)",
  },
  fontSize: {
    base: "16px",
    mobile: "14px",
  },
};
