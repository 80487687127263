interface FormatOptions {
  format: "decimal" | "currency" | "percentage" | "weight";
  locale: string;
  currency?: string;
  decimalPlaces?: number;
  prefix?: string;
  suffix?: string;
}

interface ParseOptions {
  format: string;
  locale: string;
  allowNegative: boolean;
}

export const formatNumber = (
  value: number | string,
  options: FormatOptions
): string => {
  const number = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(number)) return "";

  const {
    format,
    locale,
    currency,
    decimalPlaces = 2,
    prefix,
    suffix,
  } = options;

  let formattedValue = "";

  switch (format) {
    case "currency":
      formattedValue = new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }).format(number);
      break;

    case "percentage":
      formattedValue = new Intl.NumberFormat(locale, {
        style: "percent",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }).format(number / 100);
      break;

    case "weight":
      formattedValue = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }).format(number);
      formattedValue += " kg";
      break;

    default:
      formattedValue = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }).format(number);
  }

  return `${prefix || ""}${formattedValue}${suffix || ""}`;
};

export const parseNumber = (value: string, options: ParseOptions): number => {
  const { locale, allowNegative } = options;

  // Remove todos os caracteres exceto números, vírgula, ponto e sinal de menos
  let cleanValue = value.replace(/[^\d,-.]/, "");

  // Remove sinais negativos extras e mantém apenas o primeiro se permitido
  if (!allowNegative) {
    cleanValue = cleanValue.replace(/-/g, "");
  } else {
    cleanValue = cleanValue.replace(/-/g, (match, index) =>
      index === 0 ? match : ""
    );
  }

  // Trata separadores decimais baseado no locale
  const format = new Intl.NumberFormat(locale);
  const parts = format.formatToParts(1.1);
  const decimalSeparator =
    parts.find((part) => part.type === "decimal")?.value || ".";

  // Converte para o formato padrão com ponto
  const normalizedValue = cleanValue.replace(
    new RegExp(`[${decimalSeparator}]`),
    "."
  );

  const number = parseFloat(normalizedValue);
  return isNaN(number) ? 0 : number;
};
