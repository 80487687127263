import { Home } from "../pages";
import { ComponentType } from "react";
import {
  faLink,
  faQrcode,
  faChain,
  faCode,
  faCloud,
  faToolbox,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { i18n } from "i18next";

export const routes = {
  home: "/",
  toolbox: "/toolbox",
  linkAggregator: "/toolbox/link-aggregator",
  linkShortener: "/toolbox/link-shortener",
  qrCodeGenerator: "/toolbox/qr-code-generator",
};

export const routeComponents: { [key: string]: ComponentType } = {
  [routes.home]: Home,
};

export interface AppRoute {
  name: string;
  path: string;
  icon: IconDefinition;
  tools?: {
    name: string;
    path: string;
    icon: IconDefinition;
  }[];
}

export interface AppRoutes {
  [key: string]: AppRoute[];
}

export const initializeAppRoutes = (i18n: i18n): AppRoutes => ({
  Apps: [
    {
      name: i18n.t("project:apps.toolbox.app_name"),
      path: "/toolbox",
      icon: faToolbox,
      tools: [
        {
          name: i18n.t("project:apps.toolbox.tools.link_aggregator.name"),
          path: "/toolbox/link-aggregator",
          icon: faLink,
        },
        {
          name: i18n.t("project:apps.toolbox.tools.link_shortener.name"),
          path: "/toolbox/link-shortener",
          icon: faChain,
        },
        {
          name: i18n.t("project:apps.toolbox.tools.qr_code_generator.name"),
          path: "/toolbox/qr-code-generator",
          icon: faQrcode,
        },
      ],
    },
    {
      name: i18n.t("project:apps.services.app_name"),
      path: "/services",
      icon: faCloud,
      tools: [
        {
          name: i18n.t("project:apps.services.tools.api.name"),
          path: "/services/api",
          icon: faCode,
        },
        {
          name: i18n.t("project:apps.services.tools.cleecky_core.name"),
          path: "/services/cleecky-core",
          icon: faCloud,
        },
      ],
    },
  ],
});

export let appRoutes: AppRoutes;

export const setupAppRoutes = (i18nInstance: i18n) => {
  appRoutes = initializeAppRoutes(i18nInstance);
};
