import React from "react";
import styled from "styled-components";
import { GridProps } from "../../../types";

const StyledGrid = styled.div<{
  $columns: Record<string, number | undefined>;
  $autoFit?: boolean;
  $minColumnWidth?: string;
  $align?: string;
  $justify?: string;
  $gap?: string | number;
  $padding?: string | number;
  $margin?: string | number;
  $width?: string;
  $height?: string;
  $itemCount: number;
}>`
  display: grid;
  box-sizing: border-box;
  width: 100%;
  min-height: min-content;
  position: relative;

  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $gap }) =>
    $gap && `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`}
  ${({ $padding }) =>
    $padding &&
    `padding: ${typeof $padding === "number" ? `${$padding}px` : $padding};`}
  ${({ $margin }) =>
    $margin &&
    `margin: ${typeof $margin === "number" ? `${$margin}px` : $margin};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}

  ${({ $autoFit, $minColumnWidth, $columns, theme, $itemCount, $justify }) => {
    const justifyContent = {
      start: "start",
      center: "center",
      end: "end",
      "flex-start": "start",
      "flex-end": "end",
      between: "space-between",
      around: "space-around",
      evenly: "space-evenly",
      "space-between": "space-between",
      "space-around": "space-around",
      "space-evenly": "space-evenly",
    }[$justify || "start"];

    if ($autoFit && $minColumnWidth) {
      return `
        grid-template-columns: repeat(auto-fit, min-content);
        justify-content: ${justifyContent};
        grid-auto-flow: row;
        grid-auto-columns: min-content;
      `;
    }

    return Object.entries($columns)
      .map(([breakpoint, columns]) => {
        if (!columns) return "";
        const actualColumns = Math.min(columns, $itemCount);

        return `
        @media (min-width: ${theme.responsive.breakpoints[breakpoint]}) {
          grid-template-columns: repeat(${actualColumns}, min-content);
          justify-content: ${justifyContent};
          grid-auto-flow: row;
          grid-auto-columns: min-content;
        }
      `;
      })
      .join("\n");
  }}

  /* Ajuste para mobile usando o valor de columns.sm ou fallback para 2 colunas */
  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    grid-template-columns: repeat(
      ${({ $columns, $itemCount }) => {
        const smColumns = $columns.sm;
        if (!smColumns) return 2;
        return Math.min(smColumns, $itemCount);
      }},
      min-content
    );
    ${({ $gap }) => {
      if (!$gap) return "gap: 1rem;";
      return `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`;
    }}
    ${({ $justify }) => {
      const justifyContent = {
        start: "start",
        center: "center",
        end: "end",
        "flex-start": "start",
        "flex-end": "end",
        between: "space-between",
        around: "space-around",
        evenly: "space-evenly",
        "space-between": "space-between",
        "space-around": "space-around",
        "space-evenly": "space-evenly",
      }[$justify || "start"];

      return `
        justify-content: ${justifyContent};
        grid-auto-flow: row;
        grid-auto-columns: min-content;
      `;
    }}
  }

  & > * {
    width: auto;
    height: auto;
    min-width: min-content;
  }
`;

export const CleeckyCoreGrid: React.FC<GridProps> = ({
  children,
  columns = {},
  autoFit,
  minColumnWidth = "250px",
  align,
  justify,
  gap,
  padding,
  margin,
  width,
  height,
  className,
}) => {
  const itemCount = React.Children.count(children);

  return (
    <StyledGrid
      $columns={columns}
      $autoFit={autoFit}
      $minColumnWidth={minColumnWidth}
      $align={align}
      $justify={justify}
      $gap={gap}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      $itemCount={itemCount}
      className={className}>
      {children}
    </StyledGrid>
  );
};
