import React from "react";
import styled from "styled-components";
import { useCleeckyCoreResponsive } from "../../../hooks";

interface ContainerProps {
  children: React.ReactNode;
  fluid?: boolean;
  className?: string;
}

const StyledContainer = styled.div<{ $maxWidth: string }>`
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: ${({ $maxWidth }) => $maxWidth};
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CleeckyCoreContainer: React.FC<ContainerProps> = ({
  children,
  fluid = false,
  className,
}) => {
  const { currentBreakpoint, containerMaxWidths } = useCleeckyCoreResponsive();

  const maxWidth = fluid ? "100%" : containerMaxWidths[currentBreakpoint];

  return (
    <StyledContainer $maxWidth={maxWidth} className={className}>
      {children}
    </StyledContainer>
  );
};
