import React from "react";
import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";

type AnimationTrigger = "hover" | "click" | "both";

type PredefinedAnimationType =
  | "pulse"
  | "shake"
  | "bounce"
  | "tilt"
  | "scale"
  | "slide"
  | "none";

type AnimationType = PredefinedAnimationType | "custom";

interface CustomAnimation {
  initial?: any;
  animate?: any;
  whileHover?: any;
  whileTap?: any;
  transition?: any;
}

interface ButtonAnimation {
  type: AnimationType;
  trigger: AnimationTrigger;
  custom?: CustomAnimation;
}

// Adicione estes tipos antes da interface CleeckyCoreButtonProps
type ButtonFormat =
  | "auto"
  | "square"
  | "circle"
  | { width?: string; height?: string };

type AnimationConfig = ButtonAnimation | PredefinedAnimationType;

interface CleeckyCoreButtonProps {
  // Funcionalidade
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  isDisabled?: boolean;

  // Conteúdo
  text?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right" | "top";
  tooltip?: string;

  // Estilo
  variant?: "solid" | "outline" | "link" | "icon";
  size?: "xsmall" | "small" | "medium" | "large";
  textPosition?:
    | "inside-below"
    | "inside-right"
    | "outside-right"
    | "outside-below";
  customStyle?: React.CSSProperties;
  className?: string;
  borderRadius?: boolean | number | string;

  // Outros
  children?: React.ReactNode;
  "aria-label"?: string;
  iconBackground?: boolean;

  animation?: AnimationConfig;

  format?: ButtonFormat;
  fullWidth?: boolean;
}

const IconBox = styled.div<{
  $variant: string;
  $size: string;
  $iconBackground?: boolean;
  $borderRadius?: boolean | number | string;
  $format?: ButtonFormat;
  $hasText: boolean;
  $fullWidth?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: inherit;
  transform-origin: center;
  will-change: transform;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};

  ${({ $format, $hasText, $size }) => {
    if (!$format || $format === "auto") {
      return "";
    }

    if ($format === "square") {
      const size = {
        xsmall: "32px",
        small: "40px",
        medium: "48px",
        large: "56px",
      }[$size];

      return css`
        width: ${$hasText ? "auto" : size};
        height: ${size};
        min-width: ${size};
      `;
    }

    if ($format === "circle") {
      const size = {
        xsmall: "32px",
        small: "40px",
        medium: "48px",
        large: "56px",
      }[$size];

      return css`
        width: ${$hasText ? "auto" : size};
        height: ${size};
        min-width: ${size};
        border-radius: 50%;
      `;
    }

    if (typeof $format === "object") {
      return css`
        width: ${$format.width || "auto"};
        height: ${$format.height || "auto"};
        min-width: ${$format.width || "auto"};
      `;
    }
  }}

  ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean" && $borderRadius) {
      return css`
        border-radius: 9999px;
      `;
    } else if (typeof $borderRadius === "boolean" && !$borderRadius) {
      return css`
        border-radius: none;
      `;
    }
    if (typeof $borderRadius === "number") {
      return css`
        border-radius: ${$borderRadius}px;
      `;
    }
    if (typeof $borderRadius === "string") {
      return css`
        border-radius: ${$borderRadius};
      `;
    }
  }}

  ${({ theme, $variant, $iconBackground }) => {
    const { colors } = theme;

    switch ($variant) {
      case "solid":
        return css`
          background: ${colors.interactive.clickable};
          color: ${colors.text.onGradient};
          border: none;

          &:hover {
            background: ${theme.gradient?.highlightColor ||
            colors.interactive.active};
          }
        `;
      case "outline":
        return css`
          background: transparent;
          color: ${colors.text.primary};
          border: 2px solid ${colors.interactive.clickable};

          &:hover {
            border-color: ${theme.gradient?.highlightColor ||
            colors.interactive.active};
            color: ${theme.gradient?.highlightColor ||
            colors.interactive.active};
          }
        `;
      case "link":
        return css`
          background: transparent;
          color: ${colors.text.primary};
          border: none;

          &:hover {
            color: ${theme.gradient?.highlightColor};
          }
        `;
      case "icon":
        return css`
          background: ${$iconBackground
            ? colors.background.card
            : "transparent"};
          color: ${colors.text.primary};
          border: none;
          padding: ${$iconBackground ? "0.5rem" : "0"};

          &:hover {
            color: ${theme.gradient?.highlightColor};
            background: ${$iconBackground
              ? theme.colors.background.hover
              : "transparent"};
          }
        `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case "xsmall":
        return css`
          padding: 0.25rem 0.5rem;
          font-size: 0.75rem;
        `;
      case "small":
        return css`
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
        `;
      case "medium":
        return css`
          padding: 0.75rem 1.5rem;
          font-size: 1rem;
        `;
      case "large":
        return css`
          padding: 1rem 2rem;
          font-size: 1.125rem;
        `;
    }
  }}

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.interactive.buttonDisabled};
  }

  &:active {
    transform: scale(0.98);
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div<{
  $textPosition: string;
  $fullWidth?: boolean;
}>`
  display: inline-flex;
  position: relative;
  align-items: center;
  gap: 8px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};

  ${({ $textPosition }) => {
    switch ($textPosition) {
      case "outside-below":
        return css`
          flex-direction: column;
          align-items: center;
        `;
      case "outside-right":
        return css`
          flex-direction: row;
          align-items: center;
        `;
      case "inside-below":
        return css`
          flex-direction: column;
          align-items: center;
        `;
      case "inside-right":
      default:
        return css`
          flex-direction: row;
          align-items: center;
        `;
    }
  }}
`;

const TextLabel = styled.span<{
  $textPosition: string;
  $variant?: string;
}>`
  display: inline-block;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.primary};
  transition: color 0.3s ease;

  ${({ $textPosition }) => {
    switch ($textPosition) {
      case "outside-below":
        return css`
          margin-top: 4px;
        `;
      case "outside-right":
        return css`
          margin-left: 8px;
        `;
      case "inside-below":
        return css`
          margin-top: 4px;
        `;
      case "inside-right":
        return css`
          margin-left: 8px;
        `;
    }
  }}

  ${({ theme, $variant }) =>
    $variant === "link" &&
    css`
      &:hover {
        color: ${theme.gradient?.highlightColor};
      }
    `}
`;

const AnimationContainer = styled(motion.div)<{
  $fullWidth?: boolean;
}>`
  position: relative;
  display: inline-block;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};

  /* Importante: isso mantém o botão no fluxo normal do layout */
  & > * {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  }
`;

const predefinedAnimations: Record<PredefinedAnimationType, CustomAnimation> = {
  scale: {
    initial: { scale: 1 },
    animate: { scale: 1 },
    whileHover: { scale: 1.05 },
    whileTap: { scale: 0.95 },
    transition: { duration: 0.2 },
  },
  tilt: {
    initial: { rotate: 0 },
    animate: { rotate: 0 },
    whileHover: { rotate: 5 },
    whileTap: { rotate: -5 },
    transition: { duration: 0.2 },
  },
  pulse: {
    initial: { scale: 1 },
    animate: { scale: 1 },
    whileHover: { scale: 1.05 },
    whileTap: { scale: 1 },
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 10,
    },
  },
  shake: {
    initial: { x: 0 },
    animate: { x: 0 },
    whileHover: { x: [-2, 2, -2, 2, 0] },
    whileTap: { x: 0 },
    transition: {
      type: "spring",
      duration: 0.4,
      repeat: Infinity,
      repeatType: "mirror",
    },
  },
  bounce: {
    initial: { y: 0 },
    animate: { y: 0 },
    whileHover: { y: -6 },
    whileTap: { y: 0 },
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 8,
    },
  },
  slide: {
    initial: { x: 0 },
    animate: { x: 0 },
    whileHover: { x: 5 },
    whileTap: { x: 0 },
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 20,
    },
  },
  none: {
    initial: {},
    animate: {},
    whileHover: {},
    whileTap: {},
    transition: {},
  },
};

interface AnimationProps {
  initial: any;
  animate: any;
  whileHover?: any;
  whileTap?: any;
  transition: any;
}

export const CleeckyCoreButton: React.FC<CleeckyCoreButtonProps> = ({
  onClick,
  href,
  type = "button",
  isLoading,
  isDisabled,
  text,
  icon,
  tooltip,
  variant = "solid",
  size = "medium",
  textPosition = "inside-below",
  customStyle,
  className,
  borderRadius = true,
  children,
  "aria-label": ariaLabel,
  iconBackground = false,
  animation = {
    type: "scale",
    trigger: "both",
  },
  format = "auto",
  fullWidth = false,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (isDisabled || isLoading) return;

    if (href) {
      window.location.href = href;
    } else if (onClick) {
      onClick(e);
    }
  };

  const getAnimationProps = () => {
    if (!animation) return {};

    const animationConfig: ButtonAnimation =
      typeof animation === "string"
        ? { type: animation as PredefinedAnimationType, trigger: "both" }
        : animation;

    if (animationConfig.type === "custom" && animationConfig.custom) {
      const { custom, trigger } = animationConfig;
      return {
        initial: custom.initial,
        animate: custom.animate,
        whileHover:
          trigger === "hover" || trigger === "both"
            ? custom.whileHover
            : undefined,
        whileTap:
          trigger === "click" || trigger === "both"
            ? custom.whileTap
            : undefined,
        transition: custom.transition,
      };
    }

    const predefinedAnimation =
      predefinedAnimations[animationConfig.type as PredefinedAnimationType];
    if (predefinedAnimation) {
      const animationProps: AnimationProps = {
        initial: predefinedAnimation.initial,
        animate: predefinedAnimation.animate,
        transition: predefinedAnimation.transition,
      };

      if (
        animationConfig.trigger === "click" ||
        animationConfig.trigger === "both"
      ) {
        animationProps.whileTap = predefinedAnimation.whileTap;
      }

      if (
        animationConfig.trigger === "hover" ||
        animationConfig.trigger === "both"
      ) {
        animationProps.whileHover = predefinedAnimation.whileHover;
      }

      return animationProps;
    }

    return {};
  };

  return (
    <AnimationContainer {...getAnimationProps()} $fullWidth={fullWidth}>
      <ButtonContainer $textPosition={textPosition} $fullWidth={fullWidth}>
        <IconBox
          as={href ? "a" : "button"}
          onClick={handleClick}
          disabled={isDisabled || isLoading}
          type={type}
          $variant={variant}
          $size={size}
          $iconBackground={iconBackground}
          $borderRadius={borderRadius}
          $format={format}
          $hasText={!!text || !!children}
          $fullWidth={fullWidth}
          className={className}
          aria-label={ariaLabel}
          title={tooltip}>
          {icon && <span className="icon-wrapper">{icon}</span>}
          {text && textPosition.startsWith("inside") && (
            <TextLabel $textPosition={textPosition} $variant={variant}>
              {text}
            </TextLabel>
          )}
        </IconBox>
        {text && textPosition.startsWith("outside") && (
          <TextLabel $textPosition={textPosition} $variant={variant}>
            {text}
          </TextLabel>
        )}
        {children}
      </ButtonContainer>
    </AnimationContainer>
  );
};
