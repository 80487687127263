import React from "react";
import styled from "styled-components";
import { BaseInputProps } from "./CleeckyCoreBaseInput";

interface TextAreaProps extends Omit<BaseInputProps, "type"> {
  minRows?: number;
  maxRows?: number;
  autoResize?: boolean;
  showCharCount?: boolean;
  maxLength?: number;
}

const StyledTextArea = styled.textarea<{
  $hasError?: boolean;
  $size?: string;
  $variant?: string;
}>`
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid
    ${({ theme, $hasError }) =>
      $hasError ? theme.colors.status.error : theme.colors.border.input};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: inherit;
  font-size: 1rem;
  resize: vertical;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.interactive.primary};
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.colors.interactive.primaryAlpha};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.background.disabled};
    cursor: not-allowed;
  }
`;

const CharCount = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: right;
  margin-top: 0.25rem;
`;

export const CleeckyCoreTextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaProps &
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "type">
>((props, ref) => {
  const {
    minRows = 3,
    maxRows,
    autoResize = true,
    showCharCount = false,
    maxLength,
    onChange,
    value,
    ...rest
  } = props;

  const [charCount, setCharCount] = React.useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setCharCount(value.length);

    if (autoResize) {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    }

    onChange?.(e);
  };

  return (
    <div>
      <StyledTextArea
        ref={ref}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        style={{
          minHeight: `${minRows * 1.5}rem`,
          maxHeight: maxRows ? `${maxRows * 1.5}rem` : undefined,
        }}
        {...rest}
      />
      {showCharCount && (
        <CharCount>
          {charCount}
          {maxLength ? `/${maxLength}` : ""} caracteres
        </CharCount>
      )}
    </div>
  );
});

CleeckyCoreTextArea.displayName = "CleeckyCoreTextArea";
