import { DefaultTheme } from "styled-components";
import { CleeckyCoreGradientTheme } from "./CleeckyCoreGradientTheme";
import {
  ResponsiveConfig,
  defaultResponsiveConfig,
} from "../configs/CleeckyCoreResponsiveConfig";

export interface CleeckyCoreTheme extends DefaultTheme {
  name: string;
  type: "interface";
  responsive: ResponsiveConfig;
  colors: {
    background: {
      base: string;
      content: string;
      card: string;
      navbar: string;
      searchBar: string;
      appBox: string;
      input: string;
      modal: string;
      overlay: string;
      dropdown: string;
    };
    text: {
      primary: string;
      secondary: string;
      onGradient: string;
      placeholder: string;
      error: string;
      success: string;
      onPrimary: string;
    };
    icon: {
      primary: string;
      secondary: string;
      onGradient: string;
    };
    border: {
      input: string;
      error: string;
      success: string;
    };
    scroll: {
      track: string;
      thumb: string;
      line: string;
    };
    interactive: {
      primary: string;
      primaryHover: string;
      hover: string;
      clickable: string;
      switcherOn: string;
      switcherOff: string;
      buttonOn: string;
      buttonOff: string;
      buttonDisabled: string;
      active: string;
      selected: string;
    };
    status: {
      error: string;
      success: string;
      warning: string;
      info: string;
    };
    selection: {
      background: string;
      text: string;
    };
  };
  gradient?: CleeckyCoreGradientTheme;
}

export const createCleeckyCoreTheme = (
  theme: Partial<CleeckyCoreTheme>
): CleeckyCoreTheme => {
  return {
    ...theme,
    responsive: {
      ...defaultResponsiveConfig,
      ...theme.responsive,
    },
  } as CleeckyCoreTheme;
};
