import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

interface CleeckyCoreCardProps {
  children: React.ReactNode;
  elevation?: "none" | "low" | "medium" | "high";
  interactive?: boolean;
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
}

const CardContainer = styled(motion.div)<{
  $elevation: string;
  $interactive: boolean;
  $padding?: string;
  $margin?: string;
  $width?: string;
  $height?: string;
}>`
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 1rem;
  padding: ${({ $padding }) => $padding || "1rem"};
  margin: ${({ $margin }) => $margin || "0"};
  width: ${({ $width }) => $width || "auto"};
  height: ${({ $height }) => $height || "auto"};
  transition: all 0.2s ease-in-out;

  ${({ $elevation, theme }) => {
    switch ($elevation) {
      case "low":
        return `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);`;
      case "medium":
        return `box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);`;
      case "high":
        return `box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);`;
      default:
        return "";
    }
  }}

  ${({ $interactive }) =>
    $interactive &&
    `
    cursor: pointer;
    &:hover {
      transform: translateY(-2px);
    }
    &:active {
      transform: translateY(0);
    }
  `}
`;

export const CleeckyCoreCard: React.FC<CleeckyCoreCardProps> = ({
  children,
  elevation = "medium",
  interactive = false,
  padding,
  margin,
  width,
  height,
  className,
  onClick,
}) => {
  return (
    <CardContainer
      $elevation={elevation}
      $interactive={interactive}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      className={className}
      onClick={onClick}
      whileHover={interactive ? { scale: 1.02 } : undefined}
      whileTap={interactive ? { scale: 0.98 } : undefined}>
      {children}
    </CardContainer>
  );
};
