import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CleeckyCoreBaseInput, BaseInputProps } from "./CleeckyCoreBaseInput";
import { t } from "i18next";

type EmailInputProps = Omit<BaseInputProps, "type"> & {
  label?: string;
  placeholder?: string;
};

export const CleeckyCoreEmailInput = React.forwardRef<
  HTMLInputElement,
  EmailInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>((props, ref) => {
  const {
    label = t("cleeckycore:common.form.email.label"),
    placeholder = t("cleeckycore:common.form.email.placeholder"),
    ...rest
  } = props;

  return (
    <CleeckyCoreBaseInput
      ref={ref}
      type="email"
      prefix={<FontAwesomeIcon icon={faEnvelope} />}
      label={label}
      placeholder={placeholder}
      {...rest}
    />
  );
});

CleeckyCoreEmailInput.displayName = "CleeckyCoreEmailInput";
