import React from "react";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface FormProps {
  onSubmit: (data: any) => void;
  children: React.ReactNode;
  validationSchema?: yup.ObjectSchema<any>;
  defaultValues?: Record<string, any>;
  className?: string;
  resetOnSubmit?: boolean;
}

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CleeckyCoreForm: React.FC<FormProps> = ({
  onSubmit,
  children,
  validationSchema,
  defaultValues,
  className,
  resetOnSubmit = false,
}) => {
  const methods = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues,
  });

  const handleSubmit = async (data: any) => {
    try {
      await onSubmit(data);
      if (resetOnSubmit) {
        methods.reset();
      }
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={methods.handleSubmit(handleSubmit)}
        className={className}>
        {children}
      </StyledForm>
    </FormProvider>
  );
};
