import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

interface CleeckyCoreTooltipProps {
  children: React.ReactNode;
  content: string;
  position?: "top" | "bottom" | "left" | "right" | "cursor";
  delay?: number;
  closeDelay?: number;
  autoClose?: boolean;
}

interface TooltipContainerProps {
  $position: "top" | "bottom" | "left" | "right" | "cursor";
  $x?: number;
  $y?: number;
}

const TooltipContainer = styled(motion.div)<TooltipContainerProps>`
  position: absolute;
  background: ${({ theme }) => theme.colors.background.card};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;

  ${({ $position, $x, $y }) => {
    if ($position === "cursor" && $x !== undefined && $y !== undefined) {
      return `
        position: fixed;
        left: ${$x}px;
        top: ${$y}px;
        transform: translate(10px, 10px);
        max-width: calc(100vw - ${$x + 20}px);
        
        /* Garante que o tooltip não ultrapasse os limites da tela */
        @media (max-width: ${$x + 250}px) {
          left: auto;
          right: 10px;
          transform: translate(0, 10px);
        }
        
        /* Ajusta quando estiver próximo à borda inferior */
        @media (max-height: ${$y + 100}px) {
          top: auto;
          bottom: 10px;
          transform: translate(10px, 0);
        }
      `;
    }

    switch ($position) {
      case "top":
        return `
          bottom: 120%;
          transform-origin: bottom center;
        `;
      case "bottom":
        return `
          top: 120%;
          transform-origin: top center;
        `;
      case "left":
        return `
          right: 110%;
          transform-origin: center right;
        `;
      case "right":
        return `
          left: 110%;
          transform-origin: center left;
        `;
    }
  }}
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const CleeckyCoreTooltip: React.FC<CleeckyCoreTooltipProps> = ({
  children,
  content,
  position = "cursor",
  delay = 0,
  closeDelay = 4000,
  autoClose = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  let showTimeoutId: NodeJS.Timeout;
  let hideTimeoutId: NodeJS.Timeout;

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (hideTimeoutId) {
      clearTimeout(hideTimeoutId);
    }

    if (position === "cursor") {
      setMousePos({ x: e.clientX, y: e.clientY });
    }

    showTimeoutId = setTimeout(() => {
      setIsVisible(true);
      if (autoClose) {
        hideTimeoutId = setTimeout(() => {
          setIsVisible(false);
        }, closeDelay);
      }
    }, delay);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (position === "cursor" && isVisible) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calcula as coordenadas ajustadas
      const adjustedX = Math.min(e.clientX, viewportWidth - 200); // 200px é uma margem de segurança
      const adjustedY = Math.min(e.clientY, viewportHeight - 50); // 50px é uma margem de segurança

      setMousePos({ x: adjustedX, y: adjustedY });
    }
  };

  const handleMouseLeave = () => {
    if (showTimeoutId) {
      clearTimeout(showTimeoutId);
    }
    if (hideTimeoutId) {
      clearTimeout(hideTimeoutId);
    }
    setIsVisible(false);
  };

  return (
    <TooltipWrapper
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}>
      {children}
      <AnimatePresence>
        {isVisible && (
          <TooltipContainer
            $position={position}
            $x={mousePos.x}
            $y={mousePos.y}
            initial={{ opacity: 0, y: position === "top" ? 10 : -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: position === "top" ? 10 : -10 }}
            transition={{ duration: 0.2 }}>
            {content}
          </TooltipContainer>
        )}
      </AnimatePresence>
    </TooltipWrapper>
  );
};
