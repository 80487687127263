import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCleeckyCoreTheme } from "./CleeckyCoreThemeProvider";
import { predefinedAnimations } from "../animations/CleeckyCoreGradientAnimations";
import { customGradientAnimations } from "themes/gradient-animation";
import { Tooltip, Button } from "../../components";
import { gradientThemes, interfaceThemes } from "themes";
import { t } from "i18next";

interface ContainerProps {
  $isOpen: boolean;
}

interface ColorButtonProps {
  $isActive: boolean;
  color: string;
}

const SwitcherContainer = styled.div<ContainerProps>`
  position: fixed;
  right: ${({ $isOpen }) => ($isOpen ? "0" : "-320px")};
  top: 0;
  width: 320px;
  height: 100vh;
  background: ${({ theme }) => theme.colors.background.content};
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1001;
`;

const SwitcherContent = styled.div`
  padding: 24px;
`;

const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 16px;
  font-size: 1.1rem;
`;

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin-bottom: 24px;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.text.onGradient};
  font-size: 1.2rem;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const ColorButton = styled.button<ColorButtonProps>`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.background.card};
  cursor: pointer;
  position: relative;
  background: ${({ color }) => color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  ${CheckIcon} {
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  }

  &:hover {
    transform: scale(1.05);
    border-color: ${({ theme }) => theme.colors.interactive.active};
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    `
    border-color: ${theme.colors.interactive.selected};
    border-width: 3px;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  `}

  &:hover::after {
    content: attr(data-name);
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.colors.background.card};
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const MenuTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: start;
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  position: relative;
`;

const formatThemeName = (name: string) => {
  return name
    .split(/(?=[A-Z])|[-_]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const ThemeSwitcherContainer = styled.div<ContainerProps>`
  position: fixed;
  bottom: 20px;
  right: ${({ $isOpen }) => ($isOpen ? "340px" : "20px")};
  transition: right 0.3s ease;
  z-index: 1001;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 12px;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 8px;
`;

const ToggleLabel = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.9rem;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

const Slider = styled.span<{ $isChecked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, $isChecked }) =>
    $isChecked ? theme.gradient?.highlightColor : theme.colors.background.base};
  transition: 0.4s;
  border-radius: 24px;
  opacity: ${({ $isChecked }) => ($isChecked ? 1 : 0.5)};

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: ${({ theme }) => theme.colors.text.onGradient};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ $isChecked }) =>
      $isChecked ? "translateX(24px)" : "translateX(0)"};
  }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const AnimationConfigContainer = styled.div`
  margin-top: 12px;
  padding: 12px;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 8px;
`;

const AnimationSelect = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.background.base};
  background: ${({ theme }) => theme.colors.background.content};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.9rem;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.gradient?.highlightColor};
  }
`;

export const CleeckyCoreThemeSwitcher: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    currentTheme,
    currentGradient,
    availableThemes,
    availableGradients,
    setTheme,
    setGradient,
  } = useCleeckyCoreTheme();

  if (!availableThemes.length || !availableGradients.length) {
    return null;
  }

  return (
    <>
      <ThemeSwitcherContainer $isOpen={isOpen}>
        <Tooltip
          content={t("cleeckycore:common.interface.settings.title")}
          position="left">
          <Button
            icon={<FontAwesomeIcon icon={faPalette} />}
            onClick={() => setIsOpen(!isOpen)}
            variant="icon"
            size="medium"
            format="circle"
            iconBackground
            animation={{
              type: "tilt",
              trigger: "hover",
            }}
            aria-label={t("cleeckycore:common.interface.settings.title")}
          />
        </Tooltip>
      </ThemeSwitcherContainer>

      <SwitcherContainer $isOpen={isOpen}>
        <SwitcherContent>
          <MenuHeader>
            <MenuTitle>
              {t("cleeckycore:common.interface.settings.menu_title")}
            </MenuTitle>
            <Button
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => setIsOpen(false)}
              variant="icon"
              size="small"
              tooltip={t("cleeckycore:common.actions.close")}
              aria-label={t("cleeckycore:common.actions.close")}
            />
          </MenuHeader>
          <SectionTitle>
            {t("cleeckycore:common.interface.settings.colors.interface")}
          </SectionTitle>
          <ColorGrid>
            {availableThemes.map((themeName) => (
              <ColorButton
                key={themeName}
                color={interfaceThemes[themeName].colors.background.base}
                $isActive={currentTheme.name === themeName}
                data-name={formatThemeName(themeName)}
                onClick={() => setTheme(themeName)}>
                <CheckIcon icon={faCheck} />
              </ColorButton>
            ))}
          </ColorGrid>

          <SectionTitle>
            {t("cleeckycore:common.interface.settings.colors.highlight")}
          </SectionTitle>
          <ColorGrid>
            {availableGradients.map((gradientName) => (
              <ColorButton
                key={gradientName}
                color={gradientThemes[gradientName].highlightColor}
                $isActive={currentGradient?.name === gradientName}
                data-name={formatThemeName(gradientName)}
                onClick={() =>
                  setGradient(gradientName, {
                    enableAnimation: currentGradient?.enableAnimation ?? true,
                    animation: currentGradient?.animation,
                  })
                }>
                <CheckIcon icon={faCheck} />
              </ColorButton>
            ))}
          </ColorGrid>

          <SectionTitle>
            {t("cleeckycore:common.interface.settings.animations.background")}
          </SectionTitle>
          <ToggleContainer>
            <ToggleLabel>
              {t("cleeckycore:common.interface.settings.animations.enable")}
            </ToggleLabel>
            <Switch>
              <Checkbox
                checked={currentGradient?.enableAnimation ?? true}
                onChange={(e) => {
                  if (currentGradient) {
                    const newEnableAnimation = e.target.checked;
                    setGradient(currentGradient.name, {
                      enableAnimation: newEnableAnimation,
                      animation: currentGradient.animation,
                    });
                  }
                }}
              />
              <Slider $isChecked={currentGradient?.enableAnimation ?? true} />
            </Switch>
          </ToggleContainer>

          {currentGradient?.enableAnimation && (
            <AnimationConfigContainer>
              <AnimationSelect
                value={currentGradient?.animation?.name || "wave"}
                onChange={(e) => {
                  if (currentGradient) {
                    const selectedAnimation = e.target.value;
                    const animation =
                      predefinedAnimations[selectedAnimation] ||
                      customGradientAnimations[selectedAnimation];

                    setGradient(currentGradient.name, {
                      enableAnimation: true,
                      animation: animation,
                    });
                  }
                }}>
                <optgroup
                  label={t(
                    "cleeckycore:common.interface.settings.animations.default"
                  )}>
                  {Object.keys(predefinedAnimations).map((animName) => (
                    <option key={animName} value={animName}>
                      {formatThemeName(animName)}
                    </option>
                  ))}
                </optgroup>
                <optgroup
                  label={t(
                    "cleeckycore:common.interface.settings.animations.custom"
                  )}>
                  {Object.keys(customGradientAnimations).map((animName) => (
                    <option key={animName} value={animName}>
                      {formatThemeName(animName)}
                    </option>
                  ))}
                </optgroup>
              </AnimationSelect>
            </AnimationConfigContainer>
          )}
        </SwitcherContent>
      </SwitcherContainer>
    </>
  );
};

export default CleeckyCoreThemeSwitcher;
