import React from "react";
import * as CleeckyCore from "../../CleeckyCore/components";
import { t } from "i18next";

interface SignUpModalProps {
  onToggleMode: () => void;
}

export const SignUpModal: React.FC<SignUpModalProps> = ({ onToggleMode }) => {
  const handleSubmit = async (data: any) => {
    try {
      console.log("Registro:", data);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  return (
    <CleeckyCore.Container>
      <CleeckyCore.Row>
        <CleeckyCore.Column justify="center">
          <CleeckyCore.Form onSubmit={handleSubmit} resetOnSubmit={false}>
            <CleeckyCore.Column gap="1rem">
              <CleeckyCore.TextInput
                name="name"
                label={t("project:common.auth.modal.register.form.name")}
                placeholder={t("project:common.auth.modal.register.form.name")}
                borderRadius="1rem"
                fullWidth
              />

              <CleeckyCore.EmailInput
                name="email"
                label={t("project:common.auth.modal.register.form.email")}
                placeholder={t("project:common.auth.modal.register.form.email")}
                borderRadius="1rem"
                fullWidth
              />

              <CleeckyCore.PasswordInput
                name="password"
                borderRadius="1rem"
                showToggleButton
                showStrengthMeter
                fullWidth
              />

              <CleeckyCore.ConfirmPasswordInput
                name="confirmPassword"
                borderRadius="1rem"
                showToggleButton
                fullWidth
              />

              <CleeckyCore.Button
                type="submit"
                text={t("cleeckycore:common.actions.register")}
                variant="solid"
                borderRadius={true}
                size="small"
                fullWidth
              />

              <CleeckyCore.Button
                type="button"
                text={t("cleeckycore:common.auth.modal.register.form.login")}
                variant="link"
                onClick={onToggleMode}
                fullWidth
              />
            </CleeckyCore.Column>
          </CleeckyCore.Form>
        </CleeckyCore.Column>
      </CleeckyCore.Row>
    </CleeckyCore.Container>
  );
};
