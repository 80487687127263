import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ptBR, en } from "./locales";

interface LocaleResources {
  [key: string]: {
    cleeckycore: {
      [key: string]: any;
    };
    project: {
      [key: string]: any;
    };
  };
}

const loadLocales = async (): Promise<LocaleResources> => {
  const locales: LocaleResources = {};
  const availableLocales = {
    ptBR,
    en,
  };

  try {
    await Promise.all(
      Object.keys(availableLocales).map(async (locale) => {
        try {
          const projectModule = await import(
            `/src/i18n/locales/${locale}.json`
          );
          const coreModule =
            availableLocales[locale as keyof typeof availableLocales];

          locales[locale] = {
            cleeckycore: coreModule,
            project: projectModule.default || {},
          };
        } catch (error) {
          console.warn(
            `Arquivo de tradução do projeto não encontrado para ${locale}`
          );
          locales[locale] = {
            cleeckycore:
              availableLocales[locale as keyof typeof availableLocales],
            project: {},
          };
        }
      })
    );

    return locales;
  } catch (error) {
    console.error("Erro ao carregar arquivos de tradução:", error);
    Object.entries(availableLocales).forEach(([locale, translations]) => {
      locales[locale] = {
        cleeckycore: translations,
        project: {},
      };
    });
    return locales;
  }
};

const initializeI18n = async () => {
  const resources = await loadLocales();

  console.log("Resources:", resources);

  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: "ptBR",
      defaultNS: "project",
      ns: ["cleeckycore", "project"],
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ["localStorage", "navigator"],
        caches: ["localStorage"],
      },
    });

  return i18n;
};

export { initializeI18n };
export default i18n;
