import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

type ImageFormat = "png" | "jpeg" | "webp" | "gif" | "svg";
type ImageSource = "public" | "external" | "custom";

interface ImageProps {
  // Propriedades essenciais
  src: string;
  sourceType?: ImageSource;
  alt: string;
  format?: ImageFormat;

  // Dimensões
  width?: string | number;
  height?: string | number;
  aspectRatio?: number | string;

  // Estilo
  fit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  position?: "center" | "top" | "bottom" | "left" | "right";
  borderRadius?: boolean | number | string;
  className?: string;

  // Comportamento
  loading?: "lazy" | "eager";
  draggable?: boolean;
  fallbackSrc?: string;
  crossOrigin?: "anonymous" | "use-credentials";
  referrerPolicy?: React.HTMLAttributeReferrerPolicy;

  // Animação
  animation?: {
    initial?: any;
    animate?: any;
    transition?: any;
  };
}

const ImageContainer = styled(motion.div)<{
  $width?: string | number;
  $height?: string | number;
  $aspectRatio?: number | string;
}>`
  position: relative;
  width: ${({ $width }) =>
    typeof $width === "number" ? `${$width}px` : $width || "auto"};
  height: ${({ $height }) =>
    typeof $height === "number" ? `${$height}px` : $height || "auto"};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || "auto"};
  overflow: hidden;
`;

const StyledImage = styled.img<{
  $fit?: ImageProps["fit"];
  $position?: ImageProps["position"];
  $borderRadius?: ImageProps["borderRadius"];
}>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $fit }) => $fit || "cover"};
  object-position: ${({ $position }) => $position || "center"};
  border-radius: ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean" && $borderRadius) {
      return "9999px";
    }
    if (typeof $borderRadius === "number") {
      return `${$borderRadius}px`;
    }
    if (typeof $borderRadius === "string") {
      return $borderRadius;
    }
    return "0";
  }};
`;

const Fallback = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.disabled};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const processImagePath = (
  src: string,
  sourceType: ImageSource = "custom"
): string => {
  switch (sourceType) {
    case "public":
      return `${process.env.PUBLIC_URL}/${
        src.startsWith("/") ? src.slice(1) : src
      }`;
    case "external":
      return src;
    case "custom":
    default:
      return src;
  }
};

export const CleeckyCoreImage: React.FC<ImageProps> = ({
  src,
  sourceType = "custom",
  alt,
  format,
  width,
  height,
  aspectRatio,
  fit = "cover",
  position = "center",
  borderRadius,
  className,
  loading = "lazy",
  draggable = false,
  fallbackSrc,
  crossOrigin = "anonymous",
  referrerPolicy = "no-referrer",
  animation,
}) => {
  const [error, setError] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const processedSrc = processImagePath(src, sourceType);

  const handleError = () => {
    setError(true);
    setLoaded(false);
  };

  const handleLoad = () => {
    setLoaded(true);
    setError(false);
  };

  const validateFormat = (url: string): boolean => {
    if (!format) return true;
    const extension = url.split(".").pop()?.toLowerCase();
    return extension === format;
  };

  if (!validateFormat(processedSrc)) {
    console.error(`Formato de imagem inválido. Esperado: ${format}`);
    return null;
  }

  const isExternalUrl = (url: string): boolean => {
    return (
      sourceType === "external" ||
      (sourceType === "custom" && url.startsWith("http"))
    );
  };

  return (
    <ImageContainer
      $width={width}
      $height={height}
      $aspectRatio={aspectRatio}
      className={className}
      {...animation}>
      {error && fallbackSrc ? (
        <StyledImage
          src={processImagePath(fallbackSrc, sourceType)}
          alt={alt}
          $fit={fit}
          $position={position}
          $borderRadius={borderRadius}
          loading={loading}
          draggable={draggable}
          onLoad={handleLoad}
          onError={handleError}
          crossOrigin={isExternalUrl(fallbackSrc) ? crossOrigin : undefined}
          referrerPolicy={
            isExternalUrl(fallbackSrc) ? referrerPolicy : undefined
          }
        />
      ) : error ? (
        <Fallback>Erro ao carregar imagem</Fallback>
      ) : (
        <StyledImage
          src={processedSrc}
          alt={alt}
          $fit={fit}
          $position={position}
          $borderRadius={borderRadius}
          loading={loading}
          draggable={draggable}
          onLoad={handleLoad}
          onError={handleError}
          crossOrigin={isExternalUrl(src) ? crossOrigin : undefined}
          referrerPolicy={isExternalUrl(src) ? referrerPolicy : undefined}
        />
      )}
    </ImageContainer>
  );
};
