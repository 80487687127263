export interface CleeckyCoreGradientAnimation {
  name: string;
  keyframes: string;
  duration: string;
  timingFunction: string;
  iterationCount: string | number;
}

export const defaultGradientAnimation: CleeckyCoreGradientAnimation = {
  name: "wave",
  keyframes: `
      0% {
        background-position: 0% 0%;
      }
      25% {
        background-position: 100% 0%;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 0% 100%;
      }
      100% {
        background-position: 0% 0%;
      }
    `,
  duration: "10s",
  timingFunction: "linear",
  iterationCount: "infinite",
};

export const createGradientAnimation = (
  animation: Partial<CleeckyCoreGradientAnimation>
): CleeckyCoreGradientAnimation => {
  return {
    ...defaultGradientAnimation,
    ...animation,
  };
};

export const predefinedAnimations: Record<
  string,
  CleeckyCoreGradientAnimation
> = {
  wave: {
    name: "wave",
    keyframes: `
      0% {
        background-position: 0% 0%;
      }
      25% {
        background-position: 100% 0%;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 0% 100%;
      }
      100% {
        background-position: 0% 0%;
      }
    `,
    duration: "10s",
    timingFunction: "linear",
    iterationCount: "infinite",
  },
  morph: {
    name: "morph",
    keyframes: `
      0% {
        background-size: 400% 400%;
        background-position: 0% 0%;
      }
      50% {
        background-size: 200% 200%;
        background-position: 100% 100%;
      }
      100% {
        background-size: 400% 400%;
        background-position: 0% 0%;
      }
    `,
    duration: "10s",
    timingFunction: "ease-in-out",
    iterationCount: "infinite",
  },
};
