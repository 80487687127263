import { GradientAnimation } from "CleeckyCore";

export const customGradientAnimations: Record<string, GradientAnimation> = {
  chroma: {
    name: "Chroma",
    keyframes: `
      0% {
        background-position: 0% 50%;
        filter: hue-rotate(0deg);
      }
      50% {
        background-position: 100% 50%;
        filter: hue-rotate(180deg);
      }
      100% {
        background-position: 0% 50%;
        filter: hue-rotate(360deg);
      }
    `,
    duration: "20s",
    timingFunction: "ease-in-out",
    iterationCount: "infinite",
  },
  // Adicione mais animações customizadas aqui
};
