import React from "react";
import * as yup from "yup";
import * as CleeckyCore from "../../CleeckyCore/components";
import { t } from "i18next";

interface SignInModalProps {
  onToggleMode: () => void;
}

const loginSchema = yup.object().shape({
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
  password: yup.string().required("Senha é obrigatória"),
});

export const SignInModal: React.FC<SignInModalProps> = ({ onToggleMode }) => {
  const handleSubmit = async (data: any) => {
    try {
      console.log("Login:", data);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  return (
    <CleeckyCore.Container>
      <CleeckyCore.Row>
        <CleeckyCore.Column justify="center">
          <CleeckyCore.Form
            onSubmit={handleSubmit}
            validationSchema={loginSchema}
            resetOnSubmit={false}>
            <CleeckyCore.Column gap="1rem">
              <CleeckyCore.EmailInput
                name="email"
                borderRadius="1rem"
                fullWidth
              />

              <CleeckyCore.PasswordInput
                name="password"
                borderRadius="1rem"
                showToggleButton
                fullWidth
              />

              <CleeckyCore.Button
                type="submit"
                text={t("cleeckycore:common.actions.login")}
                variant="solid"
                borderRadius={true}
                size="small"
                fullWidth
              />

              <CleeckyCore.Button
                type="button"
                text={t("cleeckycore:common.auth.modal.login.form.register")}
                variant="link"
                onClick={onToggleMode}
                fullWidth
              />
            </CleeckyCore.Column>
          </CleeckyCore.Form>
        </CleeckyCore.Column>
      </CleeckyCore.Row>
    </CleeckyCore.Container>
  );
};
