import React from "react";
import { CleeckyCoreBaseInput, BaseInputProps } from "./CleeckyCoreBaseInput";

interface TextInputProps extends Omit<BaseInputProps, "type"> {
  maxLength?: number;
  showCharCount?: boolean;
}

export const CleeckyCoreTextInput = React.forwardRef<
  HTMLInputElement,
  TextInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>((props, ref) => {
  const { maxLength, showCharCount, helperText, ...rest } = props;
  const [charCount, setCharCount] = React.useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCharCount(e.target.value.length);
    props.onChange?.(e);
  };

  const updatedHelperText = showCharCount
    ? `${charCount}${maxLength ? `/${maxLength}` : ""} caracteres${
        helperText ? ` • ${helperText}` : ""
      }`
    : helperText;

  return (
    <CleeckyCoreBaseInput
      ref={ref}
      type="text"
      maxLength={maxLength}
      helperText={updatedHelperText}
      onChange={handleChange}
      {...rest}
    />
  );
});

CleeckyCoreTextInput.displayName = "CleeckyCoreTextInput";
