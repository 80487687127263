import React, { useState } from "react";
import { SignInModal } from "./SignInModal";
import { SignUpModal } from "./SignUpModal";
import * as CleeckyCore from "../../CleeckyCore/components";
import { useTranslation } from "react-i18next";
import mikeFrente from "../../assets/images/mike-frente.jpeg";
import mikePerfil from "../../assets/images/mike-perfil.jpeg";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const { t } = useTranslation();

  const modalType = isLogin ? "login" : "register";

  return (
    <CleeckyCore.Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`project:common.auth.modal.${modalType}.title`)}
      size="medium"
      animation="scale"
      sideImage={{
        src: isLogin ? mikeFrente : mikePerfil,
        alt: isLogin ? "Mike olhando para frente" : "Mike de perfil",
        position: "left",
        fit: "cover",
        animation: {
          initial: { scale: 0.95, opacity: 0 },
          animate: { scale: 1, opacity: 1 },
          transition: { duration: 0.3, delay: 0.2 },
        },
      }}>
      {isLogin ? (
        <SignInModal onToggleMode={() => setIsLogin(false)} />
      ) : (
        <SignUpModal onToggleMode={() => setIsLogin(true)} />
      )}
    </CleeckyCore.Modal>
  );
};
