import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faCheck } from "@fortawesome/free-solid-svg-icons";
import Flag from "react-world-flags";
import { CleeckyCoreTooltip } from "../Elements/Tooltip/CleeckyCoreTooltip";
import { CleeckyCoreButton } from "../Elements/Button/CleeckyCoreButton";
import { t } from "i18next";

interface LanguageConfig {
  languageCode: string;
  name: string;
  countryCode: string;
}

interface CleeckyCoreLanguageSwitcherProps {
  languages?: LanguageConfig[];
  position?: {
    bottom?: string | number;
    right?: string | number;
    top?: string | number;
    left?: string | number;
  };
}

const defaultLanguages: LanguageConfig[] = [
  { languageCode: "ptBR", name: "Português (Brasil)", countryCode: "BR" },
  { languageCode: "en", name: "English", countryCode: "US" },
];

const mergeLanguages = (
  defaults: LanguageConfig[],
  customs?: LanguageConfig[]
): LanguageConfig[] => {
  if (!customs) return defaults;

  const merged = [...defaults];

  customs.forEach((customLang) => {
    const existingIndex = merged.findIndex(
      (lang) => lang.languageCode === customLang.languageCode
    );
    if (existingIndex >= 0) {
      merged[existingIndex] = customLang;
    } else {
      merged.push(customLang);
    }
  });

  return merged;
};

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const LanguageSwitcherContainer = styled.div<{
  $position: Required<CleeckyCoreLanguageSwitcherProps>["position"];
}>`
  position: fixed;
  bottom: ${({ $position }) => $position.bottom};
  right: ${({ $position }) => $position.right};
  top: ${({ $position }) => $position.top};
  left: ${({ $position }) => $position.left};
  z-index: 1000;
`;

const FlagContainer = styled.div`
  width: 24px;
  height: 16px;
  overflow: hidden;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Dropdown = styled(motion.div)`
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  background: ${({ theme }) => theme?.colors?.background?.dropdown};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 180px;
`;

const LanguageOption = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme?.gradient?.highlightColor};
    svg {
      color: ${({ theme }) => theme?.colors?.text?.primary};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.background?.base};
  }
`;

const LanguageName = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme?.colors?.text?.primary};
`;

const CheckIcon = styled(FontAwesomeIcon)<{ $isActive: boolean }>`
  margin-left: auto;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  color: ${({ theme }) => theme?.gradient?.highlightColor};
  transition: opacity 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme?.colors?.text?.primary};
  }
`;

export const CleeckyCoreLanguageSwitcher: React.FC<
  CleeckyCoreLanguageSwitcherProps
> = ({
  languages: customLanguages,
  position = { bottom: "20px", right: "80px", top: "auto", left: "auto" },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const availableLanguages = mergeLanguages(defaultLanguages, customLanguages);

  const currentLanguage = availableLanguages.find(
    (lang) => lang.languageCode === i18n.language
  );

  const handleLanguageChange = async (languageCode: string) => {
    await i18n.changeLanguage(languageCode);
    setIsOpen(false);
    window.location.reload();
  };

  return (
    <Container>
      <LanguageSwitcherContainer $position={position}>
        <div ref={buttonRef}>
          <CleeckyCoreTooltip
            content={t("cleeckycore:common.interface.settings.change_language")}
            position="left">
            <CleeckyCoreButton
              icon={
                currentLanguage ? (
                  <FlagContainer>
                    <Flag code={currentLanguage.countryCode} />
                  </FlagContainer>
                ) : (
                  <FontAwesomeIcon icon={faLanguage} />
                )
              }
              onClick={() => setIsOpen(!isOpen)}
              variant="icon"
              size="medium"
              format="circle"
              iconBackground
              animation={{
                type: "tilt",
                trigger: "hover",
              }}
              aria-label={t(
                "cleeckycore:common.interface.settings.change_language"
              )}
            />
          </CleeckyCoreTooltip>
        </div>

        <AnimatePresence>
          {isOpen && (
            <Dropdown
              ref={dropdownRef}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}>
              {availableLanguages.map((language) => (
                <LanguageOption
                  key={language.languageCode}
                  onClick={() => handleLanguageChange(language.languageCode)}>
                  <FlagContainer>
                    <Flag code={language.countryCode} />
                  </FlagContainer>
                  <LanguageName>{language.name}</LanguageName>
                  <CheckIcon
                    icon={faCheck}
                    $isActive={i18n.language === language.languageCode}
                  />
                </LanguageOption>
              ))}
            </Dropdown>
          )}
        </AnimatePresence>
      </LanguageSwitcherContainer>
    </Container>
  );
};
