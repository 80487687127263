import React from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export type InputSize = "small" | "medium" | "large";
export type InputVariant = "solid" | "outline" | "transparent";

type BaseInputAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "prefix" | "suffix"
>;

export interface BaseInputProps extends BaseInputAttributes {
  id?: string;
  name: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  size?: InputSize;
  variant?: InputVariant;
  fullWidth?: boolean;
  helperText?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  animation?: "none" | "fade" | "slide";
  borderRadius?: string | number | boolean;
}

const InputWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    text-align: left;
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input<{
  $hasError?: boolean;
  $size?: InputSize;
  $variant?: InputVariant;
  $borderRadius?: string | number | boolean;
}>`
  width: 100%;
  border: 1px solid
    ${({ theme, $hasError }) =>
      $hasError ? theme.colors.status.error : theme.colors.border.input};
  border-radius: ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean" && $borderRadius) {
      return "9999px";
    }
    if (typeof $borderRadius === "number") {
      return `${$borderRadius}px`;
    }
    if (typeof $borderRadius === "string") {
      return $borderRadius;
    }
    return "0.375rem";
  }};
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.primary};
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
    box-shadow: 0 0 0 2px
      ${({ theme }) =>
        `${
          theme.gradient?.highlightColor || theme.colors.interactive.primary
        }33`};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.background.disabled};
    cursor: not-allowed;
  }

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          padding: 0.5rem;
          font-size: 0.875rem;
        `;
      case "large":
        return css`
          padding: 1rem;
          font-size: 1.125rem;
        `;
      default:
        return css`
          padding: 0.75rem;
          font-size: 1rem;
        `;
    }
  }}

  ${({ $variant, theme }) => {
    switch ($variant) {
      case "outline":
        return css`
          background: transparent;
          border: 1px solid ${theme.colors.border.input};
        `;
      case "transparent":
        return css`
          background: transparent;
          border: none;
        `;
      default:
        return css`
          background: ${theme.colors.background.input};
        `;
    }
  }}
`;

const Affix = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: color 0.2s ease-in-out;
`;

const PrefixContainer = styled(Affix)`
  left: 0.75rem;
  pointer-events: none;

  ${InputContainer}:focus-within & {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

const SuffixContainer = styled(Affix)`
  right: 0.75rem;
  pointer-events: all;

  ${InputContainer}:focus-within & {
    color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }
`;

const ErrorText = styled(motion.span)`
  color: ${({ theme }) => theme.colors.status.error};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const HelperText = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export const CleeckyCoreBaseInput = React.forwardRef<
  HTMLInputElement,
  BaseInputProps
>(
  (
    {
      id,
      name,
      label,
      error,
      disabled,
      required,
      className,
      size = "medium",
      variant = "solid",
      fullWidth = true,
      helperText,
      prefix,
      suffix,
      animation = "fade",
      borderRadius,
      ...props
    },
    ref
  ) => {
    return (
      <InputWrapper $fullWidth={fullWidth} className={className}>
        {label && (
          <Label htmlFor={id || name}>
            {label}
            {required && <span style={{ color: "red" }}> *</span>}
          </Label>
        )}

        <InputContainer>
          {prefix && <PrefixContainer>{prefix}</PrefixContainer>}
          <StyledInput
            ref={ref}
            id={id || name}
            name={name}
            disabled={disabled}
            $hasError={!!error}
            $size={size}
            $variant={variant}
            $borderRadius={borderRadius}
            style={{
              paddingLeft: prefix ? "2.5rem" : undefined,
              paddingRight: suffix ? "2.5rem" : undefined,
            }}
            {...props}
          />
          {suffix && <SuffixContainer>{suffix}</SuffixContainer>}
        </InputContainer>

        {error && (
          <ErrorText
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}>
            {error}
          </ErrorText>
        )}

        {helperText && !error && <HelperText>{helperText}</HelperText>}
      </InputWrapper>
    );
  }
);

CleeckyCoreBaseInput.displayName = "CleeckyCoreBaseInput";
