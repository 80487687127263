import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { CleeckyCoreButton } from "../../Button/CleeckyCoreButton";
import dayjs, { Dayjs } from "dayjs";

interface CalendarProps {
  selectedDate: Dayjs | null;
  onChange: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const CalendarContainer = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.borderRadius.card};
  padding: 1rem;
  width: 300px;
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const MonthYear = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const WeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
`;

const Day = styled.button<{ $isSelected?: boolean; $isToday?: boolean }>`
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.interactive.primary : "transparent"};
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.text.onPrimary : theme.colors.text.primary};
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.interactive.primaryHover
        : theme.colors.interactive.hover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent;
  }

  ${({ $isToday, theme }) =>
    $isToday &&
    css`
      &::after {
        content: "";
        position: absolute;
        bottom: 2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${theme.colors.interactive.primary};
      }
    `}
`;

const NavigationButton = styled(CleeckyCoreButton)`
  padding: 0.5rem;
`;

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

export const CleeckyCoreCalendar: React.FC<CalendarProps> = ({
  selectedDate,
  onChange,
  minDate,
  maxDate,
}) => {
  const [currentDate, setCurrentDate] = useState(selectedDate || dayjs());
  const [displayMonth, setDisplayMonth] = useState(currentDate.month());
  const [displayYear, setDisplayYear] = useState(currentDate.year());

  const getDaysInMonth = (month: number, year: number) => {
    return dayjs().year(year).month(month).daysInMonth();
  };

  const getFirstDayOfMonth = (month: number, year: number) => {
    return dayjs().year(year).month(month).startOf("month").day();
  };

  const isToday = (date: Dayjs) => {
    return date.isSame(dayjs(), "day");
  };

  const isSelected = (date: Dayjs) => {
    return selectedDate?.isSame(date, "day") || false;
  };

  const isDisabled = (date: Dayjs) => {
    if (minDate && date.isBefore(minDate, "day")) return true;
    if (maxDate && date.isAfter(maxDate, "day")) return true;
    return false;
  };

  const handlePrevMonth = () => {
    if (displayMonth === 0) {
      setDisplayMonth(11);
      setDisplayYear(displayYear - 1);
    } else {
      setDisplayMonth(displayMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (displayMonth === 11) {
      setDisplayMonth(0);
      setDisplayYear(displayYear + 1);
    } else {
      setDisplayMonth(displayMonth + 1);
    }
  };

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(displayMonth, displayYear);
    const firstDay = getFirstDayOfMonth(displayMonth, displayYear);
    const days = [];

    // Preencher dias vazios no início
    for (let i = 0; i < firstDay; i++) {
      days.push(<Day key={`empty-${i}`} disabled />);
    }

    // Preencher dias do mês
    for (let day = 1; day <= daysInMonth; day++) {
      const date = dayjs().year(displayYear).month(displayMonth).date(day);
      days.push(
        <Day
          key={day}
          onClick={() => onChange(date)}
          $isSelected={isSelected(date)}
          $isToday={isToday(date)}
          disabled={isDisabled(date)}>
          {day}
        </Day>
      );
    }

    return days;
  };

  return (
    <CalendarContainer
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}>
      <Header>
        <NavigationButton
          variant="icon"
          size="small"
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          onClick={handlePrevMonth}
        />
        <MonthYear>
          {dayjs()
            .year(displayYear)
            .month(displayMonth)
            .locale("pt-BR")
            .format("MMMM YYYY")}
        </MonthYear>
        <NavigationButton
          variant="icon"
          size="small"
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={handleNextMonth}
        />
      </Header>
      <WeekDays>
        {weekDays.map((day) => (
          <div key={day}>{day}</div>
        ))}
      </WeekDays>
      <DaysGrid>{renderDays()}</DaysGrid>
    </CalendarContainer>
  );
};

CleeckyCoreCalendar.displayName = "CleeckyCoreCalendar";
