import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faUser } from "@fortawesome/free-solid-svg-icons";
import { CleeckyCoreButton } from "../Button/CleeckyCoreButton";
import { CleeckyCoreTooltip } from "../Tooltip/CleeckyCoreTooltip";
import { t } from "i18next";

interface SearchBarProps {
  // Funcionalidade
  onSearch?: (searchTerm: string) => void;
  onFilterClick?: () => void;
  onAccountClick?: () => void;
  value?: string;
  placeholder?: string;

  // Configuração visual
  showSearchIcon?: boolean;
  showFilterIcon?: boolean;
  showAccountIcon?: boolean;
  textAlign?: "left" | "center";

  // Ordem dos botões direitos (menor número = mais à esquerda)
  filterOrder?: number;
  accountOrder?: number;

  // Tooltips
  searchTooltip?: string;
  filterTooltip?: string;
  accountTooltip?: string;

  // Estilo
  size?: "xsmall" | "small" | "medium" | "large";
  variant?: "solid" | "outline" | "transparent" | "icon";
  borderRadius?: boolean | number | string;
  width?: string;
  className?: string;
}

const getSize = (size: SearchBarProps["size"]) => {
  switch (size) {
    case "xsmall":
      return { padding: "0.3rem", height: "2rem" };
    case "small":
      return { padding: "0.4rem", height: "2.4rem" };
    case "large":
      return { padding: "0.6rem", height: "3.2rem" };
    case "medium":
    default:
      return { padding: "0.5rem", height: "2.8rem" };
  }
};

const getBorderRadius = (borderRadius: SearchBarProps["borderRadius"]) => {
  if (typeof borderRadius === "boolean") {
    return borderRadius ? "2rem" : "0";
  }
  if (typeof borderRadius === "number") {
    return `${borderRadius}px`;
  }
  return borderRadius || "2rem";
};

const SearchContainer = styled.div<{
  $width?: string;
  $size?: SearchBarProps["size"];
  $variant?: SearchBarProps["variant"];
  $borderRadius?: SearchBarProps["borderRadius"];
}>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: ${({ $width }) => $width || "100%"};
  max-width: 600px;
  height: ${({ $size }) => getSize($size).height};
  padding: ${({ $size }) => getSize($size).padding};
  border-radius: ${({ $borderRadius }) => getBorderRadius($borderRadius)};
  transition: all 0.2s ease;

  ${({ $variant, theme }) => {
    switch ($variant) {
      case "outline":
        return css`
          background: transparent;
          border: 2px solid ${theme.colors.background.searchBar};

          &:focus-within {
            border-color: ${theme.gradient?.highlightColor};
            box-shadow: 0 0 0 2px ${theme.gradient?.highlightColor}33;
          }
        `;
      case "transparent":
        return css`
          background: ${theme.gradient?.highlightColor}20;
          border: none;

          &:focus-within {
            background: ${theme.gradient?.highlightColor}50;
          }
        `;
      case "icon":
        return css`
          background: transparent;
          border: none;

          &:focus-within {
            box-shadow: 0 0 0 2px ${theme.gradient?.highlightColor}33;
          }
        `;
      case "solid":
        return css`
          background: ${theme.colors.background.searchBar};
          border: none;
          box-shadow: 0 0 0 2px ${theme.colors.interactive.selected}33;

          &:focus-within {
            box-shadow: none;
            border: 1px solid ${theme.gradient?.highlightColor};
          }
        `;
      default:
        return css`
          background: ${theme.colors.background.searchBar};
          border: none;
          box-shadow: 0 0 0 2px ${theme.colors.interactive.selected}33;

          &:focus-within {
            border-color: ${theme.gradient?.highlightColor};
          }
        `;
    }
  }}
`;

const SearchInput = styled.input<{
  $textAlign: "left" | "center";
  $size?: SearchBarProps["size"];
}>`
  flex: 1;
  border: none;
  background: transparent;
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ $size }) =>
    $size === "large"
      ? "1.1rem"
      : $size === "small"
      ? "0.9rem"
      : $size === "xsmall"
      ? "0.8rem"
      : "1rem"};
  text-align: ${({ $textAlign }) => $textAlign};
  height: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.placeholder};
  }

  &:focus {
    outline: none;
  }
`;

const RightButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface ButtonConfig {
  icon: typeof faFilter | typeof faUser;
  onClick?: () => void;
  tooltip: string;
  order: number;
}

export const CleeckyCoreSearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onFilterClick,
  onAccountClick,
  value = "",
  placeholder = t("cleeckycore:common.actions.search"),
  showSearchIcon = true,
  showFilterIcon = true,
  showAccountIcon = true,
  textAlign = "left",
  filterOrder = 1,
  accountOrder = 2,
  searchTooltip = t("cleeckycore:common.actions.search"),
  filterTooltip = t("cleeckycore:common.interface.elements.search_bar.filter"),
  accountTooltip = t(
    "cleeckycore:common.interface.elements.search_bar.account"
  ),
  size = "medium",
  variant = "solid",
  borderRadius = true,
  width,
  className,
}) => {
  const [searchTerm, setSearchTerm] = React.useState(value);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    onSearch?.(newValue);
  };

  const rightButtons = [
    showFilterIcon && {
      icon: faFilter,
      onClick: onFilterClick,
      tooltip: filterTooltip,
      order: filterOrder,
    },
    showAccountIcon && {
      icon: faUser,
      onClick: onAccountClick,
      tooltip: accountTooltip,
      order: accountOrder,
    },
  ]
    .filter(Boolean)
    .map((button) => button as ButtonConfig)
    .sort((a, b) => a.order - b.order);

  return (
    <SearchContainer
      $width={width}
      $size={size}
      $variant={variant}
      $borderRadius={borderRadius}
      className={className}>
      {showSearchIcon && (
        <CleeckyCoreTooltip content={searchTooltip}>
          <CleeckyCoreButton
            variant="icon"
            size="small"
            icon={<FontAwesomeIcon icon={faSearch} />}
            aria-label="Pesquisar"
          />
        </CleeckyCoreTooltip>
      )}

      <SearchInput
        value={searchTerm}
        onChange={handleSearch}
        placeholder={placeholder}
        $textAlign={textAlign}
        $size={size}
      />

      <RightButtonsContainer>
        {rightButtons.map((button, index) => (
          <CleeckyCoreTooltip key={index} content={button.tooltip}>
            <CleeckyCoreButton
              variant="icon"
              size="small"
              icon={<FontAwesomeIcon icon={button.icon} />}
              onClick={button.onClick}
              aria-label={button.tooltip}
            />
          </CleeckyCoreTooltip>
        ))}
      </RightButtonsContainer>
    </SearchContainer>
  );
};
