import React from "react";
import styled from "styled-components";
import { RowProps } from "../../../types";

const StyledRow = styled.div<{
  $reverse?: boolean;
  $noGutters?: boolean;
  $align?: string;
  $justify?: string;
  $wrap?: boolean;
  $gap?: string | number;
  $padding?: string | number;
  $margin?: string | number;
  $width?: string;
  $height?: string;
}>`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  box-sizing: border-box;
  width: 100%;
  min-height: min-content;
  position: relative;

  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $justify }) => $justify && `justify-content: ${$justify};`}
  ${({ $gap }) =>
    $gap && `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`}
  ${({ $padding }) =>
    $padding &&
    `padding: ${typeof $padding === "number" ? `${$padding}px` : $padding};`}
  ${({ $margin }) =>
    $margin &&
    `margin: ${typeof $margin === "number" ? `${$margin}px` : $margin};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  
  /* Responsividade base */
  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    flex-direction: column;
    align-items: ${({ $justify }) => $justify || "flex-start"};
    text-align: center;

    & > * {
      width: 100% !important;
      margin-bottom: 1rem;
      justify-content: center;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  ${({ $noGutters }) =>
    !$noGutters &&
    `
    & > * {
      padding: 0.5rem;
    }
  `}
`;

export const CleeckyCoreRow: React.FC<RowProps> = ({
  children,
  reverse,
  noGutters,
  align,
  justify,
  wrap,
  gap,
  padding = "0",
  margin = "0",
  width,
  height,
  className,
}) => {
  return (
    <StyledRow
      $reverse={reverse}
      $noGutters={noGutters}
      $align={align}
      $justify={justify}
      $wrap={wrap}
      $gap={gap}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      className={className}>
      {children}
    </StyledRow>
  );
};
