import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { CleeckyCoreBaseInput, BaseInputProps } from "../CleeckyCoreBaseInput";
import { CleeckyCoreButton } from "../../Button/CleeckyCoreButton";
import { useCleeckyCoreOutsideClick } from "CleeckyCore/hooks";

interface TimePickerProps
  extends Omit<BaseInputProps, "type" | "value" | "onChange"> {
  value?: string;
  onChange?: (time: string) => void;
  format?: "12h" | "24h";
  step?: number;
  minTime?: string;
  maxTime?: string;
  clearable?: boolean;
}

const PopoverContainer = styled.div`
  position: relative;
`;

const TimePopover = styled(motion.div)`
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  background: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.borderRadius.card};
  padding: 1rem;
  width: 200px;
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  z-index: 1000;
`;

const TimeList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.disabled};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.interactive.primary};
    border-radius: 3px;
  }
`;

const TimeOption = styled.button<{ $isSelected?: boolean }>`
  padding: 0.5rem;
  border: none;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.interactive.primary : "transparent"};
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.text.onPrimary : theme.colors.text.primary};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.button};

  &:hover {
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.interactive.primaryHover
        : theme.colors.interactive.hover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const generateTimeOptions = (
  format: "12h" | "24h",
  step: number,
  minTime?: string,
  maxTime?: string
): string[] => {
  const options: string[] = [];
  const minutes = 24 * 60;
  const minMinutes = minTime ? timeToMinutes(minTime) : 0;
  const maxMinutes = maxTime ? timeToMinutes(maxTime) : minutes;

  for (let i = 0; i < minutes; i += step) {
    if (i < minMinutes || i > maxMinutes) continue;

    const hours = Math.floor(i / 60);
    const mins = i % 60;

    if (format === "12h") {
      const period = hours >= 12 ? "PM" : "AM";
      const displayHours = hours % 12 || 12;
      options.push(
        `${displayHours.toString().padStart(2, "0")}:${mins
          .toString()
          .padStart(2, "0")} ${period}`
      );
    } else {
      options.push(
        `${hours.toString().padStart(2, "0")}:${mins
          .toString()
          .padStart(2, "0")}`
      );
    }
  }

  return options;
};

const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

export const CleeckyCoreTimePicker = React.forwardRef<
  HTMLInputElement,
  TimePickerProps
>(
  (
    {
      value,
      onChange,
      format = "24h",
      step = 30,
      minTime,
      maxTime,
      clearable = true,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const timeOptions = generateTimeOptions(format, step, minTime, maxTime);

    useCleeckyCoreOutsideClick(containerRef, () => setIsOpen(false));

    const handleInputClick = () => {
      setIsOpen(true);
    };

    const handleTimeSelect = (time: string) => {
      onChange?.(time);
      setIsOpen(false);
    };

    const handleClear = (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange?.("");
    };

    return (
      <PopoverContainer ref={containerRef}>
        <CleeckyCoreBaseInput
          ref={ref}
          type="text"
          readOnly
          value={value}
          prefix={<FontAwesomeIcon icon={faClock} />}
          suffix={
            clearable && value ? (
              <CleeckyCoreButton
                variant="icon"
                size="small"
                icon="×"
                onClick={handleClear}
                aria-label="Limpar horário"
              />
            ) : undefined
          }
          onClick={handleInputClick}
          {...rest}
        />
        <AnimatePresence>
          {isOpen && (
            <TimePopover
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}>
              <TimeList>
                {timeOptions.map((time) => (
                  <TimeOption
                    key={time}
                    $isSelected={value === time}
                    onClick={() => handleTimeSelect(time)}>
                    {time}
                  </TimeOption>
                ))}
              </TimeList>
            </TimePopover>
          )}
        </AnimatePresence>
      </PopoverContainer>
    );
  }
);

CleeckyCoreTimePicker.displayName = "CleeckyCoreTimePicker";
