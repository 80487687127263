import { useEffect, useState } from "react";
import { useCleeckyCoreTheme } from "../themes/themeUtilities/CleeckyCoreThemeProvider";
import { Breakpoint } from "../configs/CleeckyCoreResponsiveConfig";

export const useCleeckyCoreResponsive = () => {
  const { currentTheme } = useCleeckyCoreTheme();
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>("xs");

  useEffect(() => {
    const checkBreakpoint = () => {
      const width = window.innerWidth;
      const breakpoints = currentTheme.responsive.breakpoints;

      if (width >= parseInt(breakpoints["2xl"])) {
        setCurrentBreakpoint("2xl");
      } else if (width >= parseInt(breakpoints.xl)) {
        setCurrentBreakpoint("xl");
      } else if (width >= parseInt(breakpoints.lg)) {
        setCurrentBreakpoint("lg");
      } else if (width >= parseInt(breakpoints.md)) {
        setCurrentBreakpoint("md");
      } else if (width >= parseInt(breakpoints.sm)) {
        setCurrentBreakpoint("sm");
      } else {
        setCurrentBreakpoint("xs");
      }
    };

    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, [currentTheme.responsive.breakpoints]);

  const isBreakpoint = (breakpoint: Breakpoint) => {
    const width = window.innerWidth;
    return width >= parseInt(currentTheme.responsive.breakpoints[breakpoint]);
  };

  return {
    currentBreakpoint,
    isBreakpoint,
    breakpoints: currentTheme.responsive.breakpoints,
    containerMaxWidths: currentTheme.responsive.containerMaxWidths,
    spacing: currentTheme.responsive.spacing,
  };
};
