import { Theme } from "../CleeckyCore/types";
import { createCleeckyCoreTheme } from "../CleeckyCore/themes";
import { defaultResponsiveConfig } from "../CleeckyCore/configs/CleeckyCoreResponsiveConfig";

interface InterfaceThemes {
  [key: string]: Theme;
}

export const lightTheme = createCleeckyCoreTheme({
  name: "light",
  type: "interface",
  responsive: defaultResponsiveConfig,
  colors: {
    background: {
      base: "#FFFFFF",
      content: "#F0F0F0",
      card: "#FFFFFF",
      navbar: "#E0E0E0",
      searchBar: "#FBFBFB",
      appBox: "#FFFFFF",
      input: "#FFFFFF",
      modal: "#FFFFFF",
      overlay: "rgba(0, 0, 0, 0.5)",
      dropdown: "#FFFFFF",
    },
    text: {
      primary: "#2E2E2E",
      secondary: "#575757",
      onGradient: "#FFFFFF",
      placeholder: "#AFB7E0",
      error: "#DC2626",
      success: "#059669",
      onPrimary: "#FFFFFF",
    },
    icon: {
      primary: "#2E2E2E",
      secondary: "#575757",
      onGradient: "#AFB7E0",
    },
    border: {
      input: "#E5E7EB",
      error: "#DC2626",
      success: "#059669",
    },
    scroll: {
      track: "rgba(0, 0, 0, 0.1)",
      thumb: "#C0C0C0",
      line: "#E0E0E0",
    },
    interactive: {
      primary: "#2563EB",
      primaryHover: "#1D4ED8",
      hover: "rgba(0, 0, 0, 0.05)",
      clickable: "#D0D0D0",
      switcherOn: "#D0D0D0",
      switcherOff: "#F0F0F0",
      buttonOn: "#2E2E2E",
      buttonOff: "#2E2E2E",
      buttonDisabled: "#C0C0C0",
      active: "#E8E8E8",
      selected: "#D5D5D5",
    },
    status: {
      error: "#DC2626",
      success: "#059669",
      warning: "#D97706",
      info: "#2563EB",
    },
    selection: {
      background: "#D0D0D0",
      text: "#2E2E2E",
    },
  },
});

export const darkTheme = createCleeckyCoreTheme({
  name: "dark",
  type: "interface",
  responsive: defaultResponsiveConfig,
  colors: {
    background: {
      base: "#2E2E2E",
      content: "#393939",
      card: "#404040",
      navbar: "#262626",
      searchBar: "#333333",
      appBox: "#333333",
      input: "#333333",
      modal: "#333333",
      overlay: "rgba(0, 0, 0, 0.7)",
      dropdown: "#333333",
    },
    text: {
      primary: "#F5F5F5",
      secondary: "#D4D4D4",
      onGradient: "#FFFFFF",
      placeholder: "#9CA3AF",
      error: "#EF4444",
      success: "#10B981",
      onPrimary: "#FFFFFF",
    },
    icon: {
      primary: "#D4D4D4",
      secondary: "#F5F5F5",
      onGradient: "#F5F5F5",
    },
    border: {
      input: "#4B5563",
      error: "#EF4444",
      success: "#10B981",
    },
    scroll: {
      track: "rgba(255, 255, 255, 0.1)",
      thumb: "#525252",
      line: "#404040",
    },
    interactive: {
      primary: "#3B82F6",
      primaryHover: "#2563EB",
      hover: "rgba(255, 255, 255, 0.1)",
      clickable: "#404040",
      switcherOn: "#404040",
      switcherOff: "#262626",
      buttonOn: "#F5F5F5",
      buttonOff: "#D4D4D4",
      buttonDisabled: "#525252",
      active: "#525252",
      selected: "#404040",
    },
    status: {
      error: "#EF4444",
      success: "#10B981",
      warning: "#F59E0B",
      info: "#3B82F6",
    },
    selection: {
      background: "#404040",
      text: "#F5F5F5",
    },
  },
});

export const nocturnTheme = createCleeckyCoreTheme({
  name: "nocturn",
  type: "interface",
  responsive: defaultResponsiveConfig,
  colors: {
    background: {
      base: "#1B1F2A",
      content: "#252A3A",
      card: "#2A2F42",
      navbar: "#141A24",
      searchBar: "#1F4357",
      appBox: "#1F4357",
      input: "#1F4357",
      modal: "#252A3A",
      overlay: "rgba(0, 0, 0, 0.7)",
      dropdown: "#252A3A",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#E0E0FF",
      onGradient: "#FFFFFF",
      placeholder: "#AFB7E0",
      error: "#FF4444",
      success: "#10B981",
      onPrimary: "#FFFFFF",
    },
    icon: {
      primary: "#A3A3C2",
      secondary: "#E0E0FF",
      onGradient: "#F5F5F5",
    },
    border: {
      input: "#3B4162",
      error: "#FF4444",
      success: "#10B981",
    },
    scroll: {
      track: "rgba(255, 255, 255, 0.1)",
      thumb: "#3B4162",
      line: "#1B1F2A",
    },
    interactive: {
      primary: "#4B6BFB",
      primaryHover: "#3B5BEB",
      hover: "rgba(255, 255, 255, 0.1)",
      clickable: "#1F4357",
      switcherOn: "#3B4162",
      switcherOff: "#1B1F2A",
      buttonOn: "#E0E0FF",
      buttonOff: "#A3A3C2",
      buttonDisabled: "#252A3A",
      active: "#2F3545",
      selected: "#262B3A",
    },
    status: {
      error: "#FF4444",
      success: "#10B981",
      warning: "#F59E0B",
      info: "#4B6BFB",
    },
    selection: {
      background: "#3B4162",
      text: "#FFFFFF",
    },
  },
});

const themes: InterfaceThemes = {
  light: lightTheme,
  dark: darkTheme,
  nocturn: nocturnTheme,
};

export default themes;
