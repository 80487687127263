import React from "react";
import { BaseInputProps, CleeckyCoreBaseInput } from "./CleeckyCoreBaseInput";
import { formatNumber, parseNumber } from "CleeckyCore/utilities/numberUtils";

interface NumberInputProps extends Omit<BaseInputProps, "type"> {
  format?: "decimal" | "currency" | "percentage" | "weight";
  locale?: string;
  currency?: string;
  decimalPlaces?: number;
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  allowNegative?: boolean;
}

export const CleeckyCoreNumberInput = React.forwardRef<
  HTMLInputElement,
  NumberInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>(
  (
    {
      format = "decimal",
      locale = "pt-BR",
      currency = "BRL",
      decimalPlaces = 2,
      min,
      max,
      step,
      prefix,
      suffix,
      allowNegative = true,
      onChange,
      value,
      ...rest
    },
    ref
  ) => {
    const [displayValue, setDisplayValue] = React.useState("");

    React.useEffect(() => {
      if (value !== undefined) {
        const formattedValue = formatNumber(String(value), {
          format,
          locale,
          currency,
          decimalPlaces,
          prefix,
          suffix,
        });
        setDisplayValue(formattedValue);
      }
    }, [value, format, locale, currency, decimalPlaces, prefix, suffix]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = String(e.target.value);
      const parsedValue = parseNumber(rawValue, {
        format,
        locale,
        allowNegative,
      });

      if (
        (min === undefined || parsedValue >= min) &&
        (max === undefined || parsedValue <= max)
      ) {
        setDisplayValue(rawValue);
        if (onChange) {
          const syntheticEvent = {
            ...e,
            target: {
              ...e.target,
              value: String(parsedValue),
            },
          };
          onChange(syntheticEvent);
        }
      }
    };

    const getInputMode = () => {
      switch (format) {
        case "decimal":
        case "currency":
        case "percentage":
        case "weight":
          return "decimal";
        default:
          return "numeric";
      }
    };

    return (
      <CleeckyCoreBaseInput
        ref={ref}
        type="text"
        inputMode={getInputMode()}
        value={displayValue}
        onChange={handleChange}
        prefix={prefix}
        suffix={suffix}
        {...rest}
      />
    );
  }
);

CleeckyCoreNumberInput.displayName = "CleeckyCoreNumberInput";
