import React from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import AppRoutes from "./routes/AppRoutes";
import "./assets/styles/globals.css";
import { AuthProvider } from "./contexts/AuthContext";
import { AlertProvider } from "./contexts/AlertContext";
import * as CleeckyCore from "CleeckyCore";

function App() {
  return (
    <Provider store={store}>
      <CleeckyCore.ThemeProvider
        defaultTheme="dark"
        defaultGradient="purpleMidnight">
        <AlertProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </AlertProvider>
      </CleeckyCore.ThemeProvider>
    </Provider>
  );
}

export default App;
